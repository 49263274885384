import makeUseStyles from "@assets/style/util/makeUseStyles"
import mergeClasses from "@assets/style/util/mergeClasses"
import { DiscoText, DiscoTextProps } from "@disco-ui"

type AITextProps = DiscoTextProps & {
  disabled?: boolean
}

function AIText(props: AITextProps) {
  const { children, disabled, ...rest } = props
  const classes = useStyles({ disabled })
  return (
    <DiscoText
      {...rest}
      classes={mergeClasses(
        {
          root: classes.root,
        },
        rest.classes
      )}
    >
      {children}
    </DiscoText>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: ({ disabled }: { disabled?: boolean }) => ({
    ...(disabled
      ? {
          color: "var(--groovy-grey-300)",
        }
      : {
          backgroundImage:
            theme.palette.type === "dark"
              ? theme.palette.aiGradient.bluePurple02
              : theme.palette.aiGradient.bluePurple03,
          backgroundClip: "text",
          textFillColor: "transparent",
        }),
  }),
}))

export default AIText
