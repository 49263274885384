import ChatChannelAnchorMarkdown from "@components/chat/channel/detail/message/suggestion/ChatChannelAnchorMarkdown"
import { useMemo } from "react"
import { TranslationLanguages } from "stream-chat"
import {
  renderText as defaultRenderText,
  DefaultStreamChatGenerics,
  StreamMessage,
} from "stream-chat-react"

export default function useRenderChatChannelMessage(
  message: StreamMessage<DefaultStreamChatGenerics>,
  userLanguage?: TranslationLanguages,
  renderText = defaultRenderText
) {
  const messageTextToRender =
    message.i18n?.[`${userLanguage}_text` as `${TranslationLanguages}_text`] ||
    message.text

  return useMemo(
    () => {
      if (!messageTextToRender) return null

      // Handle newlines in the message text to preserve them in markdown
      let messageFormatted = messageTextToRender
      if (!messageFormatted.includes("‎")) {
        messageFormatted = messageFormatted
          .replace(/\n\n/g, "\n\n") // Preserve double newlines
          .replace(/\n(?!\n)/g, "  \n") // Add two spaces before single newlines for markdown
      }

      return renderText(messageFormatted, message.mentioned_users, {
        customMarkDownRenderers: {
          link: ChatChannelAnchorMarkdown,
        },
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [message.mentioned_users, messageTextToRender]
  )
}
