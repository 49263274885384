import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import styleIf from "@assets/style/util/styleIf"
import ChatEmoji, { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"
import { DiscoButton, DiscoText, DiscoTooltip } from "@disco-ui"
import { darken, lighten } from "@material-ui/core"
import React, { FC, Suspense } from "react"
import { ReactionResponse } from "stream-chat"
import { useChatContext, useMessageContext } from "stream-chat-react"

interface IChatChannelEmojiButton {
  emoji: EmojiObject | string
  variant?: "options" | "reaction-list"
  size?: number
  reactionCount?: number
  reacted?: boolean
  reactions?: ReactionResponse[]
  loadReactions?: () => Promise<void>
}

const ChatChannelEmojiButton: FC<IChatChannelEmojiButton> = ({
  emoji,
  variant = "options",
  size = variant === "options" ? 20 : 14,
  reactionCount = 0,
  reacted = false,
  reactions = [],
  loadReactions,
}) => {
  const { handleReaction, message } = useMessageContext()
  const { client: streamClient } = useChatContext()
  const classes = useStyles({ size, reacted, variant, reactionCount })

  const emojiName = `:${typeof emoji === "string" ? emoji : emoji.name}:`
  return (
    <DiscoTooltip
      content={
        reactions.length > 0 && (
          <div className={classes.innerTooltip}>
            <div className={classes.emojiWrapper}>
              <ChatEmoji emoji={emoji} size={40} />
            </div>
            <DiscoText variant={"body-sm"} color={"common.white"}>
              {`${getUsersReacted()} reacted with ${emojiName}`}
            </DiscoText>
          </div>
        )
      }
    >
      {/* eslint-disable-next-line jsx-a11y/mouse-events-have-key-events */}
      <div className={classes.buttonWrapper} onMouseOver={handleTooltipOpen}>
        <DiscoButton
          onClick={handleEmojiClick}
          classes={{
            root: classes.button,
            leftIcon: classes.leftIcon,
          }}
          color={"transparent"}
          leftIcon={
            <Suspense fallback={null}>
              <ChatEmoji emoji={emoji} size={size} />
            </Suspense>
          }
        >
          {reactionCount > 0 && (
            <DiscoText className={classes.reactionCount} variant={"body-sm"}>
              {reactionCount}
            </DiscoText>
          )}
        </DiscoButton>
      </div>
    </DiscoTooltip>
  )

  function handleEmojiClick(evt: React.MouseEvent) {
    handleReaction(typeof emoji === "string" ? emoji : emoji.id, evt)
  }

  function handleTooltipOpen() {
    if (loadReactions && reactionCount !== reactions.length) loadReactions()
  }

  function getUsersReacted() {
    const currentReactions = [...reactions]

    // If reacted but user not in reactions yet, add them optimistically
    if (reacted && !currentReactions.some((r) => r.user?.id === streamClient.user?.id)) {
      currentReactions.push({
        user: streamClient.user,
        type: typeof emoji === "string" ? emoji : emoji.id,
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
        message_id: message.id,
      } as ReactionResponse)
    }
    // If not reacted but user is in reactions, remove them optimistically
    else if (!reacted) {
      const index = currentReactions.findIndex(
        (r) => r.user?.id === streamClient.user?.id
      )
      if (index !== -1) {
        currentReactions.splice(index, 1)
      }
    }

    let text = ""
    for (let i = 0; i < currentReactions.length; i++) {
      if (currentReactions[i].user?.name) {
        if (i > 0) text += i === currentReactions.length - 1 ? " and " : ", "
        const { name, id } = currentReactions[i].user!
        if (id === streamClient.user?.id) {
          text += i > 0 ? "you" : "You"
        } else {
          text += name
        }
      }
    }
    return text
  }
}

interface StyleProps {
  size: number
  reacted: boolean
  variant: "options" | "reaction-list"
  reactionCount?: number
}

const useStyles = makeUseStyles((theme) => ({
  reactionCount: (props: StyleProps) => ({
    display: "inline-block",
    fontWeight: 500,
    color: props.reacted
      ? theme.palette.type === "light"
        ? lighten(theme.palette.primary.main, 0.2)
        : theme.palette.common.white
      : "currentColor",
  }),
  button: (props: StyleProps) => ({
    width: props.variant === "options" ? "18px" : "22px",
    height: props.variant === "options" ? "18px" : "18px",
    backgroundColor: "transparent",
    padding: 0,
    minWidth: "auto",
  }),
  buttonWrapper: (props: StyleProps) => ({
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    border: `2px solid ${
      props.reacted ? lighten(theme.palette.primary.main, 0.2) : "transparent"
    }`,
    backgroundColor: props.reacted
      ? theme.palette.type === "light"
        ? lighten(theme.palette.primary.main, 0.9)
        : darken(theme.palette.primary.main, 0.6)
      : theme.palette.groovy.neutral[200],
    padding: theme.spacing(0.5, 1),
    borderRadius: theme.measure.borderRadius.xl,
    cursor: "pointer",
    "&:hover": {
      backgroundColor: props.reacted
        ? theme.palette.type === "light"
          ? lighten(theme.palette.primary.main, 0.7)
          : darken(theme.palette.primary.main, 0.3)
        : theme.palette.groovy.neutral[100],
    },
    ...styleIf(props.variant === "options", {
      padding: theme.spacing(0.75, 0.5),
      borderRadius: "50%",
      backgroundColor: props.reacted
        ? lighten(theme.palette.primary.main, 0.2)
        : theme.palette.background.paper,
    }),
  }),
  leftIcon: (props: StyleProps) => ({
    height: "100%",
    marginRight: props.reactionCount ? theme.spacing(0.5) : 0,
    display: "flex",
    alignItems: "center",
  }),
  innerTooltip: {
    margin: theme.spacing(1, 0),
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(0.5),
  },
  emojiWrapper: {
    paddingTop: theme.spacing(1.25),
    backgroundColor: theme.palette.common.white,
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.default,
    height: "40px",
    width: "40px",
  },
}))

export default React.memo(ChatChannelEmojiButton, (prev, next) => {
  return (
    prev.emoji === next.emoji &&
    prev.variant === next.variant &&
    prev.size === next.size &&
    prev.reactionCount === next.reactionCount &&
    prev.reacted === next.reacted &&
    prev.reactions?.length === next.reactions?.length
  )
})
