import CommunityBadge from "@/community/CommunityBadge"
import CommunitySwitcherList from "@/community/switcher/CommunitySwitcherList"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDropdown, DiscoDropdownProps, DiscoTooltipProps } from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { TestIDProps } from "@utils/typeUtils"

interface CommunitySwitcherButtonProps extends TestIDProps {
  dropdownPosition?: Partial<
    Pick<DiscoDropdownProps, "horizontal" | "vertical" | "transformOrigin">
  >
  tooltipPlacement?: DiscoTooltipProps["placement"]
  classes?: Partial<ClassNameMap<"menuButton">>
}

function CommunitySwitcherButton({
  testid = "CommunitySwitcherButton",
  dropdownPosition,
  tooltipPlacement,
  classes: customClasses,
}: CommunitySwitcherButtonProps) {
  const activeOrganization = useActiveOrganization()
  const { authUser } = useAuthUser()

  const classes = useStyles()
  // must be within a community to switch to a different one
  if (!activeOrganization) return null

  const communityBadge = (
    <CommunityBadge
      testid={`${testid}.badge.${activeOrganization.slug}`}
      organizationKey={activeOrganization}
      size={40}
      classes={{ badge: classes.badge }}
    />
  )

  if (authUser?.isTest) {
    return communityBadge
  }

  return (
    <>
      <DiscoDropdown
        testid={`${testid}.dropdown`}
        menuClasses={{ paper: classes.paper }}
        vertical={dropdownPosition?.vertical ?? "top"}
        horizontal={dropdownPosition?.horizontal ?? "right"}
        transformOrigin={
          dropdownPosition?.transformOrigin ?? {
            vertical: 0,
            horizontal: -4,
          }
        }
        menuButton={(menuButtonProps) => (
          <DiscoContainerButton
            {...menuButtonProps}
            testid={`${testid}.community-switcher-button`}
            tooltip={"Communities"}
            tooltipProps={{
              placement: tooltipPlacement,
            }}
            className={customClasses?.menuButton}
          >
            {communityBadge}
          </DiscoContainerButton>
        )}
      >
        <CommunitySwitcherList />
      </DiscoDropdown>
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  badge: {
    position: "relative",
    borderRadius: theme.measure.borderRadius.medium,
    overflow: "hidden",
    boxShadow: `0px 7px 0px -5px ${theme.palette.groovy.onDark[300]}`,
  },
  paper: {
    // !important is needed to override the default DiscoDropdown paper styles
    padding: `0 !important`,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.insideCard,
    // prevent the dropdown from rendering overtop of its anchor button in cases where it may be too long
    maxHeight: "85dvh",
  },
}))

export default CommunitySwitcherButton
