import {
  ActiveOrganizationContextValue,
  useActiveOrganization,
} from "@/core/context/ActiveOrganizationContext"
import {
  ActiveProductContextValue,
  useActiveProduct,
} from "@/core/context/ActiveProductContext"
import { useLabel } from "@/core/context/LabelsContext"
import {
  extractContentUsageId,
  extractOccurrenceId,
  isOrganizationLandingPageUrl,
  isOrganizationLevelRoute,
  isProductLevelRoute,
  isProductUrl,
} from "@/core/route/util/routeUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelAttachBlock from "@components/chat/channel/attachment/ChatChannelAttachBlock"
import { CustomChatChannelLinkAttachment } from "@components/chat/channel/attachment/CustomChatChannelLinkAttachment"
import { VALID_ATTACH_BLOCK_TYPES } from "@components/chat/channel/detail/message/ChatChannelMessage"
import { ChatChannelFilePreviewer } from "@components/chat/channel/uploads/ChatChannelFilePreviewer"
import { ChatChannelImagePreviewer } from "@components/chat/channel/uploads/ChatChannelImagePreviewer"
import { AttachBlockEntity } from "@components/editor/plugins/attach-block/AttachBlockNode"
import { DiscoAlert } from "@disco-ui"
import { useEffect, useRef, useState } from "react"
import {
  DefaultStreamChatGenerics,
  LinkPreview,
  useChannelStateContext,
  useMessageInputContext,
} from "stream-chat-react"
import { v4 as uuidv4 } from "uuid"

/** Display a list of uploaded images, files
 * Based off the `stream-chat-react` component
 * ref: https://github.com/GetStream/stream-chat-react/blob/master/src/components/MessageInput/UploadsPreview.tsx
 */
type Props = {
  setPreviewLinksToHide?: React.Dispatch<React.SetStateAction<string[]>>
  previewLinksToHide?: string[] | undefined
}

export const ChatChannelUploadsPreview = <
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>({
  setPreviewLinksToHide,
  previewLinksToHide,
}: Props) => {
  const { maxNumberOfFiles, multipleUploads } =
    useChannelStateContext<StreamChatGenerics>("ChatChannelUploadsPreview")
  const messageInput = useMessageInputContext<StreamChatGenerics>("UploadsPreview")
  const { linkPreviews: linkPreviewMap, dismissLinkPreview } = useMessageInputContext()
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()
  const memberLabel = useLabel("organization_member")
  const { attachments, uploadNewFiles, removeAttachments, uploadAttachment } =
    messageInput
  const [messageAttachments, setMessageAttachments] = useState(attachments)

  const linkPreviewsArray = Array.from(linkPreviewMap.values())

  useEffect(() => {
    setMessageAttachments(attachments)
  }, [attachments, messageInput])

  const classes = useStyles()
  const uuidRef = useRef(uuidv4())
  // Filter attachments by type
  const imageAttachments = messageAttachments.filter(
    (attachment) => attachment.type === "image" && !attachment.og_scrape_url
  )
  const fileAttachments = messageAttachments.filter((attachment) =>
    ["file", "audio", "video"].includes(attachment.type || "")
  )
  const attachBlockAttachments = messageAttachments.filter((attachment) =>
    VALID_ATTACH_BLOCK_TYPES.includes(attachment.type as AttachBlockEntity)
  )
  return (
    <div className={classes.scroll}>
      <div className={classes.previewContainer}>
        {linkPreviewsArray.length > 0 && (
          <>
            {linkPreviewsArray
              .filter(
                (link) =>
                  link.state !== "dismissed" &&
                  !previewLinksToHide?.some(
                    (excludedLinks) => excludedLinks === link.og_scrape_url
                  )
              )
              .map((link) => {
                if (
                  shouldHideDiscoLink(
                    link.og_scrape_url,
                    activeOrganization,
                    activeProduct
                  )
                )
                  return null
                return (
                  <CustomChatChannelLinkAttachment
                    key={link.og_scrape_url}
                    link={link}
                    removeLink={() => {
                      handleDismissLink(link)
                    }}
                  />
                )
              })}
          </>
        )}
        {}
        {imageAttachments.length > 0 && (
          <ChatChannelImagePreviewer
            disabled={
              !multipleUploads ||
              (maxNumberOfFiles !== undefined &&
                messageAttachments.length >= maxNumberOfFiles)
            }
            handleFiles={uploadNewFiles}
            handleRemove={(id) => {
              removeAttachments([id])
            }}
            handleRetry={(id) => {
              const attachment = imageAttachments.find((a) => a.localMetadata?.id === id)
              if (attachment?.localMetadata?.file) {
                uploadAttachment(attachment)
              }
            }}
            imageUploads={imageAttachments}
            multiple={multipleUploads}
          />
        )}
        {fileAttachments.length > 0 && (
          <ChatChannelFilePreviewer
            handleFiles={uploadNewFiles}
            handleRemove={(id) => removeAttachments([id])}
            handleRetry={(id) => {
              const attachment = fileAttachments.find((a) => a.localMetadata?.id === id)
              if (attachment?.localMetadata?.file) {
                uploadAttachment(attachment)
              }
            }}
            uploads={fileAttachments}
          />
        )}
        {/* render divider only if there are files and images and content */}
        {fileAttachments.length + imageAttachments.length - messageAttachments.length !==
          0 &&
          (fileAttachments.length > 0 || imageAttachments.length > 0) && (
            <div className={classes.divider} />
          )}

        {attachBlockAttachments.map((attachment) =>
          (() => {
            return (
              <ChatChannelAttachBlock
                type={attachment.type as AttachBlockEntity}
                entityId={attachment.text!}
                handleRemove={(id) => {
                  removeAttachBlock(id)
                }}
              />
            )
          })()
        )}
      </div>
      {/* Only display alert for products, events, and content */}
      {messageAttachments.some((attachment) =>
        VALID_ATTACH_BLOCK_TYPES.includes(attachment.type as AttachBlockEntity)
      ) && (
        <DiscoAlert
          message={`Only ${memberLabel.plural} with access will be able to view the attachments.`}
          dismissibleKey={`ChatChannelUploadsPreview-${uuidRef.current}`}
        />
      )}
    </div>
  )
  function removeAttachBlock(id: string) {
    const attachment = messageAttachments.find((attach) => attach.text === id)
    if (!attachment) return
    removeAttachments([attachment.localMetadata?.id])
  }
  function handleDismissLink(link: LinkPreview) {
    dismissLinkPreview(link)
    const dismissedLinks = linkPreviewsArray
      .filter((linkPreview) => linkPreview.state === "dismissed")
      .map((linkPreview) => linkPreview.og_scrape_url)

    if (setPreviewLinksToHide) {
      setPreviewLinksToHide(dismissedLinks)
    }
  }
}

export function shouldHideDiscoLink(
  link: string,
  activeOrganization: ActiveOrganizationContextValue,
  activeProduct: ActiveProductContextValue | null
) {
  const shouldHideDiscoLinkPreview =
    link &&
    (isOrganizationLevelRoute(link, {
      orgSlug: activeOrganization.slug,
    }) ||
      isProductLevelRoute(link, {
        orgSlug: activeOrganization.slug,
        productSlug: activeProduct?.slug,
      }) ||
      extractOccurrenceId(link) !== null ||
      extractContentUsageId(link) !== null ||
      isProductUrl(link)) &&
    !isOrganizationLandingPageUrl(link)

  return shouldHideDiscoLinkPreview
}

const useStyles = makeUseStyles((theme) => ({
  scroll: {
    width: "100%",
  },
  previewContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    gap: theme.spacing(2),
  },

  divider: {
    height: "80px",
    borderLeft: `1.5px solid ${theme.palette.divider}`,
  },
}))
