import { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"

const PRODUCT_GENERAL_CHANNEL_PREFIX = "product_general_channel"
const CHAT_GENERAL_CHANNEL_PREFIX = "general_channel"
const DEFAULT_CHAT_CHANNEL_ICON_DIAMETER = 24
const CUSTOM_MESSAGING_CHANNEL_TYPE = "mastermind-custom-messaging"
const CHAT_CHANNEL_MAX_ROWS = 5

const defaultReactions: EmojiObject[] = [
  {
    colons: ":+1:",
    emoticons: [],
    id: "+1",
    name: "+1",
    native: "👍",
    shortcodes: ":+1:",
    unified: "1f44d",
  },
  {
    colons: ":heart:",
    emoticons: ["<3"],
    id: "heart",
    name: "heart",
    native: "❤️",
    shortcodes: ":heart:",
    unified: "2764-fe0f",
  },
  {
    colons: ":laughing:",
    emoticons: [":>", ":->"],
    id: "laughing",
    name: "laughing",
    native: "😆",
    shortcodes: ":laughing:",
    unified: "1f606",
  },
]

export {
  CHAT_CHANNEL_MAX_ROWS,
  CHAT_GENERAL_CHANNEL_PREFIX,
  CUSTOM_MESSAGING_CHANNEL_TYPE,
  DEFAULT_CHAT_CHANNEL_ICON_DIAMETER,
  PRODUCT_GENERAL_CHANNEL_PREFIX,
  defaultReactions,
}
