import makeUseStyles from "@assets/style/util/makeUseStyles"
import useRenderChatChannelMessage from "@components/chat/channel/detail/message/useRenderChatChannelMessage"
import { QuotedMessage as DefaultQuotedMessage } from "@components/chat/channel/detail/quoted-message/ChatChannelDefaultQuotedMessage"
import classNames from "classnames"
import React from "react"
import {
  renderText as DefaultRenderText,
  DefaultStreamChatGenerics,
  StreamMessage,
  isOnlyEmojis,
  messageHasAttachments,
  useComponentContext,
  useMessageContext,
  useTranslationContext,
} from "stream-chat-react"

export type MessageTextProps<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
> = {
  /* Replaces the CSS class name placed on the component's inner `div` container */
  customInnerClass?: string
  /* Adds a CSS class name to the component's outer `div` container */
  customWrapperClass?: string
  /* The `StreamChat` message object, which provides necessary data to the underlying UI components (overrides the value stored in `MessageContext`) */
  message?: StreamMessage<StreamChatGenerics>
  /* Theme string to be added to CSS class names */
  theme?: string
}

function UnMemoizedMessageTextComponent<
  StreamChatGenerics extends DefaultStreamChatGenerics = DefaultStreamChatGenerics
>(props: MessageTextProps<StreamChatGenerics>) {
  const {
    customInnerClass,
    customWrapperClass = "",
    message: propMessage,
    theme = "simple",
  } = props

  const { QuotedMessage = DefaultQuotedMessage } =
    useComponentContext<StreamChatGenerics>("MessageText")

  const {
    message: contextMessage,
    onMentionsClickMessage,
    onMentionsHoverMessage,
    unsafeHTML,
    renderText = DefaultRenderText,
  } = useMessageContext<StreamChatGenerics>("MessageText")

  const { t, userLanguage } = useTranslationContext("MessageText")
  const message = propMessage || contextMessage
  const hasAttachment = messageHasAttachments(message)

  const messageText = useRenderChatChannelMessage(message, userLanguage, renderText)

  const classes = useStyles()

  const wrapperClass = customWrapperClass || "str-chat__message-text"
  const innerClass =
    customInnerClass ||
    `str-chat__message-text-inner str-chat__message-${theme}-text-inner`

  if (!messageText && !message.quoted_message) return null

  return (
    /* eslint-disable jsx-a11y/no-noninteractive-tabindex */
    <div className={wrapperClass} tabIndex={0} style={{ outline: "none" }}>
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/mouse-events-have-key-events, jsx-a11y/click-events-have-key-events */}
      <div
        className={classNames(innerClass, classes.text, {
          [`str-chat__message-${theme}-text-inner--has-attachment`]: hasAttachment,
          [`str-chat__message-${theme}-text-inner--is-emoji`]:
            isOnlyEmojis(message.text) && !message.quoted_message,
        })}
        data-testid={"message-text-inner-wrapper"}
        onClick={onMentionsClickMessage}
        onMouseOver={onMentionsHoverMessage}
      >
        {message.quoted_message && <QuotedMessage />}
        {message.type === "error" && (
          <div
            className={`str-chat__${theme}-message--error-message str-chat__message--error-message`}
          >
            {t<string>("Error · Unsent")}
          </div>
        )}
        {message.status === "failed" && (
          <div
            className={`str-chat__${theme}-message--error-message str-chat__message--error-message`}
          >
            {message.errorStatusCode === 403
              ? t<string>("Message Failed · Unauthorized")
              : t<string>("Message Failed · Click to try again")}
          </div>
        )}
        {unsafeHTML && message.html ? (
          // eslint-disable-next-line react/no-danger
          <div dangerouslySetInnerHTML={{ __html: message.html }} />
        ) : (
          <div className={"fs-mask"}>{messageText}</div>
        )}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  text: {
    color: theme.palette.text.primary,
  },
}))

export const MessageText = React.memo(
  UnMemoizedMessageTextComponent
) as typeof UnMemoizedMessageTextComponent
