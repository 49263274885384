import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { shouldHideDiscoLink } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { DiscoIconButton, DiscoText } from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useState } from "react"
import { Attachment, StreamChat } from "stream-chat"
import { DefaultStreamChatGenerics, StreamMessage } from "stream-chat-react"

export type Props = {
  attachment: Attachment<DefaultStreamChatGenerics>
  message: StreamMessage<DefaultStreamChatGenerics>
  client: StreamChat<DefaultStreamChatGenerics>
}

export const ChatChannelLinkPreviewer = ({ attachment, message, client }: Props) => {
  const classes = useStyles()
  const [openLinkModal, setOpenLinkModal] = useState(false)
  const activeOrganization = useActiveOrganization()!
  const activeProduct = useActiveProduct()

  const currentPreviewLinksToHide = Array.isArray(message.previewLinksToHide)
    ? message.previewLinksToHide
    : []

  // do not render a link preview if it is supposed to be hidden or an invalid link
  if (
    currentPreviewLinksToHide.includes(attachment.og_scrape_url) ||
    (!attachment.image_url && !attachment.title && !attachment.text) ||
    (attachment.og_scrape_url &&
      shouldHideDiscoLink(attachment.og_scrape_url, activeOrganization, activeProduct))
  ) {
    return null
  }
  return (
    <>
      <div className={classes.linkPreviewContainer}>
        <DiscoIconButton
          height={20}
          width={20}
          svgStyles={{ height: 11, width: 11 }}
          onClick={() => setOpenLinkModal(true)}
          className={classes.icon}
        >
          <CloseIcon />
        </DiscoIconButton>
        <div className={classes.linkPreview}>
          {/* link attachments are always classified as either video or image */}
          {attachment.type === "image" && attachment.image_url && (
            <img
              key={attachment.id}
              src={attachment.image_url}
              alt={attachment.title}
              className={classes.imageThumbnail}
            />
          )}
          {attachment.type === "video" && (
            <video src={attachment.og_scrape_url} className={classes.imageThumbnail} />
          )}

          <div className={classes.linkPreviewText}>
            {attachment.title && (
              <DiscoText variant={"body-md-600"}>{attachment.title}</DiscoText>
            )}
            {attachment.text && (
              <DiscoText variant={"body-sm"}>{attachment.text}</DiscoText>
            )}
            <DiscoText variant={"body-sm"} color={"groovy.neutral.400"}>
              {attachment.og_scrape_url}
            </DiscoText>
          </div>
        </div>
      </div>

      <DiscoWarningModal
        testid={"closeLinkPreview"}
        isOpen={openLinkModal}
        confirmationButtonProps={{
          onClick: handleCloseLinkPreview,
          children: "Yes, remove it",
        }}
        onClose={() => setOpenLinkModal(false)}
        modalContentLabel={"Remove Link Preview"}
        title={`Remove preview?`}
        description={"Are you sure you want to remove this link preview?"}
      />
    </>
  )

  function handleCloseLinkPreview() {
    setOpenLinkModal(false)
    updatePreviewLinksToHide()
  }

  async function updatePreviewLinksToHide() {
    await client.partialUpdateMessage(message.id, {
      set: {
        previewLinksToHide: [...currentPreviewLinksToHide, attachment.og_scrape_url],
      },
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  linkPreviewContainer: {
    position: "relative",
  },
  linkPreview: {
    display: "flex",
    flexDirection: "column",
    borderLeft: `2px solid ${theme.palette.divider}`,
    paddingLeft: theme.spacing(1.5),
    gap: theme.spacing(2),
  },
  linkPreviewText: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(0.5),
  },
  imageThumbnail: {
    borderRadius: theme.measure.borderRadius.medium,
    width: "280px",
    height: "150px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  icon: {
    position: "absolute",
    left: "-30px",
    top: "-5px",
  },
}))
