import data, { EmojiMartData } from "@emoji-mart/data"
import classNames from "classnames"
import { init } from "emoji-mart"
import React, { useCallback } from "react"
import {
  AutoCompleteTextarea,
  ChatAutoCompleteProps,
  LoadingIndicator,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react"

// Initialize emoji-mart
init({ data })

function MessageInputAutoComplete(
  props: ChatAutoCompleteProps & {
    maxRows?: number
    grow?: boolean
    customClassName?: string
  }
) {
  const {
    AutocompleteSuggestionItem: SuggestionItem,
    AutocompleteSuggestionList: SuggestionList,
  } = useComponentContext()

  const {
    handleSubmit,
    onChange,
    onPaste,
    value,
    maxRows,
    placeholder,
    rows,
    onFocus,
    grow,
    customClassName,
  } = props

  const messageInput = useMessageInputContext()
  const { cooldownRemaining, disabled, textareaRef: innerRef } = messageInput

  const updateInnerRef = useCallback(
    (ref) => {
      if (innerRef) {
        innerRef.current = ref
      }
    },
    [innerRef]
  )

  function emojiReplace(word: string) {
    // Handle emoticons directly (like :) or :-)
    const emojiData = data as unknown as EmojiMartData
    const emoji = Object.values(emojiData.emojis).find((e) => e.emoticons?.includes(word))
    if (emoji) {
      return emoji.skins[0].native
    }

    // Handle :emoji_name: format
    if (word.startsWith(":") && word.endsWith(":")) {
      const emojiName = word.slice(1, -1)
      const emojiMatch = emojiData.emojis[emojiName]
      if (emojiMatch) {
        return emojiMatch.skins[0].native
      }
    }

    // Return null to not replace the word
    return null
  }

  // Limit paste to 5000 characters
  const textValue =
    value?.slice(undefined, 5000) || messageInput.text.slice(undefined, 5000)

  return (
    <AutoCompleteTextarea
      additionalTextareaProps={{
        ...messageInput.additionalTextareaProps,
        maxLength: 5000,
        style: { resize: "none" },
      }}
      className={classNames("str-chat__textarea__textarea", customClassName)}
      closeCommandsList={messageInput.closeCommandsList}
      containerClassName={"str-chat__textarea"}
      disabled={disabled || !!cooldownRemaining}
      disableMentions={messageInput.disableMentions}
      s
      dropdownClassName={"str-chat__emojisearch"}
      grow={grow || messageInput.grow}
      handleSubmit={handleSubmit || messageInput.handleSubmit}
      innerRef={updateInnerRef}
      itemClassName={"str-chat__emojisearch__item"}
      shouldSubmit={messageInput.shouldSubmit}
      listClassName={"str-chat__emojisearch__list"}
      loadingComponent={LoadingIndicator}
      maxRows={maxRows || messageInput.maxRows}
      minChar={0}
      onChange={onChange || messageInput.handleChange}
      onFocus={onFocus}
      onPaste={onPaste || messageInput.onPaste}
      placeholder={cooldownRemaining ? "Slow Mode ON" : placeholder}
      rows={rows || 1}
      showCommandsList={messageInput.showCommandsList}
      SuggestionItem={SuggestionItem}
      SuggestionList={SuggestionList}
      trigger={messageInput.autocompleteTriggers || {}}
      value={textValue}
      replaceWord={emojiReplace}
      minRows={rows || 1}
    />
  )
}

export default React.memo(MessageInputAutoComplete)
