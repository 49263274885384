import { useBotMessageInput } from "@/chat/channel/BotSuggestionsContext"
import AiApi from "@/common/AiApi"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import RestfulUtil from "@/core/restful/RestfulUtil"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { GlobalID } from "@/relay/RelayTypes"
import Relay from "@/relay/relayUtils"
import useTrackAiActivity from "@/reporting/useTrackAIActivity"
import { CreateMessageWithAIQueryParams } from "@components/chat/channel/CreateMessageWithAIButton"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import ChatChannelDetailFooterSendButton from "@components/chat/channel/detail/send-button/ChatChannelDetailFooterSendButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import MessageAttachContentBlockButton from "@components/chat/channel/message-input/MessageAttachContentBlockButton"
import MessageInputAutoComplete from "@components/chat/channel/message-input/MessageInputAutoComplete"
import { ChatChannelMessageInputQuery } from "@components/chat/channel/message-input/__generated__/ChatChannelMessageInputQuery.graphql"
import { ChatChannelUploadsPreview } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { CHAT_CHANNEL_MAX_ROWS } from "@components/chat/channel/util/chatChannelConstants"
import { getUrlAttachments } from "@components/chat/util/chatUtils"
import { displayWarningToast } from "@components/toast/ToastProvider"
import {
  DiscoButtonSkeleton,
  DiscoDivider,
  DiscoIcon,
  DiscoInputSkeleton,
  DiscoText,
  DiscoTooltip,
} from "@disco-ui"
import DiscoWarningModal from "@disco-ui/modal/DiscoWarningModal"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useIsMobile } from "@utils/hook/screenSizeHooks"
import classNames from "classnames"
import { ClipboardEvent, useEffect, useState } from "react"
import { FileUploadButton, ImageDropzone } from "react-file-utils"
import { graphql, useLazyLoadQuery } from "react-relay"
import {
  SendButton as DefaultSendButton,
  useChannelStateContext,
  useComponentContext,
  useMessageInputContext,
} from "stream-chat-react"
import { v4 as uuidv4 } from "uuid"
type Props = {
  chatChannelId: GlobalID
  forThread?: boolean
  isDirectMessage?: boolean
  setAttachBlockData?: () => void
  setPreviewLinksToHide?: React.Dispatch<React.SetStateAction<string[]>>
}

function ChatChannelMessageInput(props: Props) {
  const { chatChannelId, forThread = false, isDirectMessage = false } = props
  const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext()
  const [{ aiGid, aiTemplate }, setParams] =
    useQueryParamState<CreateMessageWithAIQueryParams>()

  const isMobile = useIsMobile()
  const activeOrganization = useActiveOrganization()!
  const [showDiscardInputModal, setShowDiscardInputModal] = useState<boolean>(false)
  const trackAiActivity = useTrackAiActivity()
  const {
    cooldownRemaining,
    isUploadEnabled,
    maxFilesLeft,
    uploadNewFiles,
    parent,
    attachments,
    onPaste,
    setText,
    text,
    insertText,
    handleSubmit,
  } = useMessageInputContext()

  useBotMessageInput({
    threadId: parent?.id,
    // Only use bot in threads
    disabled: !forThread,
  })

  // We should find a way to refactor this so we are only fetching the products required by the attachment urls
  const { node } = useLazyLoadQuery<ChatChannelMessageInputQuery>(
    graphql`
      query ChatChannelMessageInputQuery($id: ID!) {
        node(id: $id) {
          ... on Organization {
            __typename
            products(type: "course") {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "store-and-network",
      fetchKey: "chat-channel-message-input",
    }
  )
  const organization = Relay.narrowNodeType(node, "Organization")

  useEffect(() => {
    // never stream AI message suggestions in threads (and only the designated channel)
    if (chatChannelId !== aiGid || forThread) return

    if (text) {
      setShowDiscardInputModal(true)
    } else {
      handleCreateMessage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aiGid, chatChannelId])

  const classes = useStyles()
  const { SendButton = DefaultSendButton } = useComponentContext()

  if (!organization) return null
  return (
    <>
      <div className={classes.container}>
        <div className={classes.dividerContainer}>
          <DiscoDivider thickness={1} marginTop={0} marginBottom={0} />
        </div>

        <div
          className={classNames(classes.root, "str-chat__input-flat", {
            "direct-message": isDirectMessage,
            "str-chat__input-flat--send-button-active": SendButton,
            "str-chat__input-flat-quoted": quotedMessage,
          })}
          data-testid={"ChatChannelMessageInput.container"}
        >
          <ImageDropzone
            accept={acceptedFiles}
            disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
            handleFiles={uploadNewFiles}
            maxNumberOfFiles={maxFilesLeft}
            multiple={multipleUploads}
          >
            {/* Quote Preview */}
            {quotedMessage && !forThread && (
              <ChatChannelQuotedMessagePreview quotedMessage={quotedMessage} />
            )}

            <div className={"str-chat__input-flat-wrapper"}>
              <div className={"str-chat__input-flat--textarea-wrapper"}>
                {isUploadEnabled && (
                  <ChatChannelUploadsPreview
                    setPreviewLinksToHide={props.setPreviewLinksToHide}
                  />
                )}

                {/* Message */}
                <div className={classes.input}>
                  <div className={classes.inputMessage}>
                    <MessageInputAutoComplete
                      onPaste={handlePaste}
                      placeholder={"Type a message"}
                      rows={1}
                      maxRows={CHAT_CHANNEL_MAX_ROWS}
                      grow
                      value={text}
                      handleSubmit={handleSubmit}
                    />
                  </div>
                </div>

                <div className={classes.actionsContainer}>
                  {/* Actions */}
                  <div className={classes.inputActions}>
                    <MessageAttachContentBlockButton
                      setText={setText}
                      attachments={attachments}
                      text={text}
                      classes={classes.iconButton}
                    />
                    {/* Emoji Picker */}
                    {!isMobile && (
                      <ChatChannelEmojiPickerDropdown
                        classes={classes.iconButton}
                        onEmojiSelect={insertText}
                        useNativeEmoji
                      />
                    )}

                    {isUploadEnabled && !cooldownRemaining && (
                      <DiscoTooltip content={"Attach Files"}>
                        <div data-testid={"fileinput"} className={classes.iconButton}>
                          <FileUploadButton
                            accepts={acceptedFiles}
                            handleFiles={handleFileUpload}
                            multiple={multipleUploads}
                          >
                            <DiscoIcon icon={"file"} />
                          </FileUploadButton>
                        </div>
                      </DiscoTooltip>
                    )}
                    {text.length === 5000 && (
                      <DiscoText
                        variant={"body-sm-600"}
                        color={"error.main"}
                        marginLeft={1.5}
                      >
                        {"Max message limit reached: 5000 characters"}
                      </DiscoText>
                    )}
                  </div>
                  <div>
                    {/* Send */}
                    {!cooldownRemaining && (
                      <ChatChannelDetailFooterSendButton sendMessage={handleSubmit} />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </ImageDropzone>
        </div>
      </div>

      <DiscoWarningModal
        testid={"ChatCHannelMessageInput.discard-input-warning-modal"}
        isOpen={showDiscardInputModal}
        modalContentLabel={"Replace draft with new message"}
        title={`Looks like there's a draft in progress!`}
        description={
          "Do you want keep your existing draft or discard it and replace it with a new message?"
        }
        confirmationButtonProps={{
          onClick: () => {
            handleCreateMessage()
            closeDiscardInputModal()
          },
          children: "Replace message",
        }}
        cancelButtonText={"Keep draft"}
        onCancel={handleKeepDraft}
        onClose={closeDiscardInputModal}
      />
    </>
  )

  function handleFileUpload(files: FileList | File[]) {
    // maximum 10 attachments per message
    if (attachments.length + files.length <= 10) {
      uploadNewFiles(files)
    } else {
      displayWarningToast({
        message: "You can only upload 10 attachments at a time.",
        testid: `overflow-attachments-toast`,
      })
    }
  }

  function handleKeepDraft() {
    clearQueryParams()
    closeDiscardInputModal()
  }

  function closeDiscardInputModal() {
    setShowDiscardInputModal(false)
  }

  async function handleCreateMessage() {
    if (!aiGid) return
    await RestfulUtil.createStream({
      startStreamCb: () =>
        AiApi.generateMessage({ channelId: aiGid, template: aiTemplate ?? "default" }),
      handleText: (chunk) => {
        setText(chunk)
        return true
      },
      onStreamEnd: (data) => {
        if (!data.botResponseId) return

        trackAiActivity({
          kind: "message_suggestion_drafted",
          entityId: data.botResponseId,
        })

        setParams({ aiBrId: data.botResponseId })
      },
    })
    clearQueryParams()
  }

  function clearQueryParams() {
    setParams({ aiGid: undefined, aiTemplate: undefined }, "replace")
  }

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    onPaste(event)
    const pastedText = event.clipboardData.getData("Text")
    const products = Relay.connectionToArray(organization?.products)
    const urlAttachments = getUrlAttachments(pastedText, activeOrganization, products)

    if (urlAttachments && urlAttachments.length > 0) {
      const localAttachments = urlAttachments.map((attachment) => ({
        ...attachment,
        localMetadata: {
          id: uuidv4(),
        },
      }))
      attachments.push(...localAttachments)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.background.paper,
    position: "relative",
  },
  root: {
    maxWidth: `${theme.measure.page.contentMaxWidth}px`,
    margin: "0 auto",
    padding: theme.spacing(1, 2.5),
    "& .rfu-dropzone": {
      "& .rfu-dropzone__notifier": {
        display: "none", // Hide by default
      },
      "&.rfu-dropzone--accept .rfu-dropzone__notifier": {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        zIndex: theme.zIndex.raise3,
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.measure.borderRadius.medium,
        "& .rfu-dropzone__inner": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(2),
          "& svg": {
            color: theme.palette.primary.main,
          },
          "& p": {
            color: theme.palette.text.primary,
            margin: 0,
            ...theme.typography["body-md"],
          },
        },
      },
    },
    "& [data-testid='fileinput']": {
      "& .rfu-file-upload-button": {
        "& label": {
          margin: 0,
          padding: 0,
          cursor: "pointer",
          "& input": {
            display: "none",
          },
          "& svg": {
            fill: "transparent",
            color: theme.palette.groovy.grey[300],
            opacity: 1,
            width: "20px",
            height: "20px",
            "&:hover": {
              color: theme.palette.groovy.grey[500],
            },
          },
        },
      },
    },
    "& .rfu-image-upload-container": {
      opacity: 0,
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(0, 0, 0, 0.05)",
      transition: "opacity 0.2s ease-in-out",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      pointerEvents: "none",
      "&.rfu-dropzone-active": {
        opacity: 1,
        pointerEvents: "auto",
      },
    },
    "& .rfu-file-upload-button": {
      position: "relative",
      top: "auto",
      right: "auto",
      "& svg": {
        fill: "transparent",
        opacity: 1,
        width: "20px",
        height: "20px",
        "&:hover": {
          color: theme.palette.groovy.grey[500],
        },
      },
      "& label": {
        height: "30px",
        width: "30px",
        borderRadius: "50%",
        padding: "3px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& span": {
          display: "none",
        },
      },
    },
    "& .str-chat__input-flat": {
      padding: theme.spacing(1, 2.5),
      backgroundColor: theme.palette.background.paper,
      display: "list-item",
      listStyleType: "none",
      "&.direct-message": {
        "& .str-chat__textarea > textarea": {
          padding: theme.spacing(1),
          backgroundColor: `${theme.palette.groovy.neutral[100]} !important`,
        },
        "& .str-chat__textarea textarea": {
          width: "100%",
        },
      },
    },
    "& .str-chat__input-flat-wrapper": {
      display: "block",
      width: "100%",
    },
    "& .str-chat__input-flat--textarea-wrapper": {
      gap: theme.spacing(2),
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    "& .str-chat__textarea": {
      display: "flex",
      alignItems: "center",
      width: "100%",
      "& > textarea": {
        minHeight: "unset",
        height: "auto",
        width: "100%",
        border: "none !important",
        boxShadow: "none !important",
        outline: `none !important`,
        background: "transparent !important",
        padding: theme.spacing(1, 2),
        borderRadius: theme.measure.borderRadius.medium,
        color: `${theme.palette.text.primary} !important`,
        ...theme.typography["body-sm"],
        "&:focus": {
          border: "none !important",
          boxShadow: "none !important",
          outline: `1.5px solid ${theme.palette.groovy.neutral[300]} !important`,
          background: "transparent !important",
          ...theme.typography["body-sm"],
        },
        "&::placeholder": {
          ...theme.typography["body-sm"],
          color: theme.palette.groovy.grey[300],
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxHeight: "40px",
        },
      },
    },
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: "100%",
  },
  inputMessage: {
    display: "flex",
    width: "100%",
    height: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.medium,
  },
  actionsContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  inputActions: {
    display: "flex",
    alignItems: "center",
    alignSelf: "flex-end",
    zIndex: theme.zIndex.raise2,
    padding: "5px",
  },
  iconButton: {
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.grey[100]
          : theme.palette.groovy.grey[400],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  dividerContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
  },
  skeletonInput: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1.5),
    padding: theme.spacing(4, 2.5, 1),
    alignItems: "flex-end",
  },
}))

export function ChatChannelMessageInputSkeleton() {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.dividerContainer}>
        <DiscoDivider thickness={1} marginTop={0} marginBottom={0} />
      </div>
      <div className={classes.skeletonInput}>
        <DiscoInputSkeleton height={57} style={{ width: "100%" }} />
        <DiscoButtonSkeleton width={"100px"} />
      </div>
    </div>
  )
}

export default ChatChannelMessageInput
