import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useQueryParamState } from "@disco-ui/tabs/DiscoQueryParamTabs"
import { useEffect, useRef } from "react"
import { Channel } from "stream-chat"
import {
  DefaultStreamChatGenerics,
  useChannelActionContext,
  useChatContext,
} from "stream-chat-react"

interface JumpToMessageProps {
  streamChannel: Channel<DefaultStreamChatGenerics>
}

function JumpToMessage({ streamChannel }: JumpToMessageProps) {
  const [{ streamMsgId, openThread }, setQueryParam] = useQueryParamState<{
    streamMsgId?: string
    openThread?: string
    cbMsgId?: string
  }>()

  const { jumpToMessage, openThread: streamOpenThread } = useChannelActionContext()
  const activeOrganization = useActiveOrganization()!
  const { client } = useChatContext()

  const buttonRef = useRef<HTMLButtonElement>(null)

  // Jump to Message
  useEffect(() => {
    if (!streamMsgId) return
    if (streamChannel.type === "disco-chat-bot") return // Don't use this query param for chat bot channels. This prevents a bug where you have the param in your URL and the bot drawer is open and we try to look for the message but can't find it
    jumpToMessage(streamMsgId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamMsgId])

  // Open Thread
  useEffect(() => {
    if (!buttonRef.current || !openThread || openThread.toLowerCase() !== "true") return
    buttonRef.current.click()
  }, [streamMsgId, openThread])

  // Jump to latest unread message
  useEffect(() => {
    if (streamChannel.type !== "disco-chat-bot") return // Only do this for chat bot channels
    if (streamChannel.state.unreadCount === 0) return // Skip if there's no unread messages

    const viewerStreamChatUserId = activeOrganization.viewerMembership?.streamChatUserId
    if (!viewerStreamChatUserId) return

    const lastReadDate = streamChannel.state.read[viewerStreamChatUserId].last_read
    const messages = [...streamChannel.state.messages]
    const latestUnreadMessage = messages
      .sort((a, b) => a.created_at.getTime() - b.created_at.getTime())
      .find((msg) => msg.created_at > lastReadDate)
    if (!latestUnreadMessage) return

    setQueryParam({ cbMsgId: latestUnreadMessage.id })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [streamChannel])

  return <button ref={buttonRef} style={{ display: "none" }} onClick={handleOpenThread} />

  function handleOpenThread(e: React.BaseSyntheticEvent) {
    if (!openThread || !streamMsgId || !streamChannel.id) return

    client
      .search(
        {
          id: { $eq: streamChannel.id },
        },
        {
          id: { $eq: streamMsgId },
        },
        { limit: 1 }
      )
      .then((results) => {
        const { message } = results.results[0]
        streamOpenThread(message, e)
      })
  }
}

export default JumpToMessage
