/**
 * @generated SignedSource<<8b867b270b4fddd0d7b9c029253174aa>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
export type chatUtilsProductQuery$variables = {
  organizationSlug: string;
  productSlug: string;
};
export type chatUtilsProductQuery$data = {
  readonly product: {
    readonly id: string;
    readonly name: string;
  } | null;
};
export type chatUtilsProductQuery = {
  response: chatUtilsProductQuery$data;
  variables: chatUtilsProductQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "organizationSlug"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "productSlug"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "organizationSlug",
        "variableName": "organizationSlug"
      },
      {
        "kind": "Variable",
        "name": "slug",
        "variableName": "productSlug"
      }
    ],
    "concreteType": "Product",
    "kind": "LinkedField",
    "name": "product",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "chatUtilsProductQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "chatUtilsProductQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "e33fe6525edfd38c3f97ae61ab6280d1",
    "id": null,
    "metadata": {},
    "name": "chatUtilsProductQuery",
    "operationKind": "query",
    "text": "query chatUtilsProductQuery(\n  $productSlug: String!\n  $organizationSlug: String!\n) {\n  product(slug: $productSlug, organizationSlug: $organizationSlug) {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "d64c5970fb9aa95ab617b15962b1f803";

export default node;
