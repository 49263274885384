import AdminOnboardingChecklistProgressIcon from "@/admin/onboarding-checklist/AdminOnboardingChecklistProgressIcon"
import CommunitySwitcherButton from "@/community/switcher/CommunitySwitcherButton"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useOnboardingChecklistContext } from "@/core/context/OnboardingChecklistContext"
import BookmarksNavButton from "@/main/page/header/BookmarksNavButton"
import BrainSearchNavButton from "@/main/page/header/BrainSearchNavButton"
import AdminButton from "@/main/page/header/buttons/AdminButton"
import ChatButton from "@/main/page/header/buttons/ChatButton"
import CommunityButton from "@/main/page/header/buttons/CommunityButton"
import EventsButton from "@/main/page/header/buttons/EventsButton"
import NotificationCenterButton from "@/main/page/header/buttons/NotificationCenterButton"
import ProductsButton from "@/main/page/header/buttons/ProductsButton"
import SearchButton from "@/main/page/header/buttons/SearchButton"
import useIsWebView from "@/product/util/hook/useIsWebView"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import OpenAIBotDrawerButton from "@components/ai-bot/OpenAIBotDrawerButton"
import AuthenticatedUserDropdown from "@components/authenticated-user-dropdown/AuthenticatedUserDropdown"
import GlobalAddDropdown from "@components/global-add/GlobalAddDropdown"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import { useIsMobile, useIsTablet } from "@utils/hook/screenSizeHooks"
import useFeatureFlags from "@utils/hook/useFeatureFlags"
import usePermissions from "@utils/hook/usePermissions"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

export interface CommunityAppBarProps extends TestIDProps {
  variant?: "horizontal" | "vertical"
  classes?: Partial<ClassNameMap<"sidebar">>
}
export default function CommunityAppBar({
  testid = "CommunityAppBar",
  variant = "vertical",
  classes: customClasses,
}: CommunityAppBarProps) {
  const { globalSearch, aiChatBot, globalAdd, brainSearch } = useFeatureFlags()
  const activeOrganization = useActiveOrganization()!
  const permissions = usePermissions(activeOrganization)
  const isTablet = useIsTablet()
  const isWebView = useIsWebView()
  const classes = useStyles({
    variant,
    isTablet,
    isWide: activeOrganization.showGlobalTabTitles,
  })
  const isMobile = useIsMobile()
  const subscriptionStanding = useSubscriptionStanding()

  const { shouldHideChecklist } = useOnboardingChecklistContext()

  if (isWebView) return null

  // If the subscription needs help, show the nav, but only the admin section and the switcher
  if (subscriptionStanding !== "active") {
    return (
      <div
        className={classNames(classes.sidebar, classes[variant], customClasses?.sidebar)}
        data-testid={`${testid}.container`}
      >
        <div className={classes.topContainer}>
          <CommunitySwitcherButton
            testid={testid}
            dropdownPosition={
              variant === "horizontal"
                ? {
                    horizontal: "center",
                    vertical: "top",
                    transformOrigin: {
                      vertical: "bottom",
                      horizontal: "center",
                    },
                  }
                : undefined // use defaults
            }
            tooltipPlacement={variant === "horizontal" ? "top" : "right"}
            classes={{
              menuButton:
                variant === "horizontal"
                  ? classes.menuButtonHorizontal
                  : classes.menuButtonVertical,
            }}
          />
          {(activeOrganization?.viewerIsOwnerOrAdmin ||
            activeOrganization.viewerMembership?.hasInstructorOrManagerRole) &&
            !isMobile && <AdminButton variant={variant} />}
        </div>
        {/* don't show for horizontal mobile footer */}
        {variant === "vertical" && (
          <div className={classes.bottomContainer}>
            <AuthenticatedUserDropdown
              dropdownPosition={{
                horizontal: "right",
                vertical: "bottom",
                transformOrigin: {
                  vertical: 0,
                  horizontal: -4,
                },
              }}
            />
          </div>
        )}
      </div>
    )
  }

  return (
    <div
      className={classNames(classes.sidebar, classes[variant], customClasses?.sidebar)}
      data-testid={`${testid}.container`}
    >
      <div className={classes.topContainer}>
        <CommunitySwitcherButton
          testid={testid}
          dropdownPosition={
            variant === "horizontal"
              ? {
                  horizontal: "center",
                  vertical: "top",
                  transformOrigin: {
                    vertical: "bottom",
                    horizontal: "center",
                  },
                }
              : undefined // use defaults
          }
          tooltipPlacement={variant === "horizontal" ? "top" : "right"}
          classes={{
            menuButton:
              variant === "horizontal"
                ? classes.menuButtonHorizontal
                : classes.menuButtonVertical,
          }}
        />
        <CommunityButton variant={variant} />
        {activeOrganization!.isProductsEnabled && <ProductsButton variant={variant} />}
        {activeOrganization?.isEventsEnabled && <EventsButton variant={variant} />}
        {(activeOrganization?.isChannelsEnabled || activeOrganization?.isDmEnabled) && (
          <ChatButton variant={variant} />
        )}
        {globalSearch && isMobile && <SearchButton variant={variant} />}
        {variant === "horizontal" && (
          <NotificationCenterButton
            testid={`${testid}.notification-center-button`}
            variant={variant}
          />
        )}
        {variant === "horizontal" && (
          <BookmarksNavButton testid={`${testid}.bookmarks-button`} variant={variant} />
        )}
        {brainSearch && (
          <BrainSearchNavButton testid={`${testid}.user-ai`} variant={variant} />
        )}
        {(activeOrganization?.viewerIsOwnerOrAdmin ||
          activeOrganization.viewerMembership?.hasInstructorOrManagerRole) &&
          !isMobile && <AdminButton variant={variant} />}
      </div>
      {/* don't show for horizontal mobile footer */}
      {variant === "vertical" && (
        <div className={classes.bottomContainer}>
          {aiChatBot &&
            activeOrganization.isChatBotEnabled &&
            permissions.has("chat_bot.interact") && <OpenAIBotDrawerButton />}
          {!shouldHideChecklist && (
            <AdminOnboardingChecklistProgressIcon testid={"CommunityAppBar"} />
          )}
          {globalAdd && !isMobile && <GlobalAddDropdown />}
          <AuthenticatedUserDropdown
            dropdownPosition={{
              horizontal: "right",
              vertical: "bottom",
              transformOrigin: {
                vertical: 0,
                horizontal: -4,
              },
            }}
          />
        </div>
      )}
    </div>
  )
}

type StyleProps = {
  variant: "horizontal" | "vertical"
  isTablet: boolean
  isWide: boolean
}

const useStyles = makeUseStyles((theme) => ({
  sidebar: {
    display: "flex",
    gap: theme.spacing(1.5),
  },
  vertical: ({ isWide }: StyleProps) => ({
    height: "100%",
    width: isWide ? 100 : theme.measure.page.communitySwitcherWidth,
    flexShrink: 0,
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(2.5, 2),
    overflowY: "auto",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "-ms-overflow-style": "none",
  }),
  horizontal: {
    width: "100%",
    minHeight: 56,
    flexDirection: "row-reverse",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: theme.palette.background.paper,
    borderTop: theme.palette.constants.borderSmall,
    padding: theme.spacing(1.5, 0),
  },
  topContainer: ({ variant, isTablet }: StyleProps) => ({
    display: "flex",
    flexDirection: variant === "horizontal" ? "row" : "column",
    alignItems: "center",
    gap: theme.spacing(variant === "horizontal" ? 0.25 : 1.5),
    justifyContent: "space-evenly",
    // for tablets, center the buttons container by unsetting the width
    width: isTablet ? "unset" : "100%",
  }),
  bottomContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  menuButtonHorizontal: {
    // padding and negative margin hack to offset the popover bottom 4px away from top of button
    paddingTop: theme.spacing(0.5),
    marginTop: theme.spacing(-0.5),
  },
  menuButtonVertical: {
    marginBottom: theme.spacing(0.75),
  },
}))
