/**
 * @generated SignedSource<<17c5a3dde5154c1df0c2736f0ab3f7ea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelPinnedMessagesDrawerFragment$data = {
  readonly id: string;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelPinnedMessagesListFragment">;
  readonly " $fragmentType": "ChatChannelPinnedMessagesDrawerFragment";
};
export type ChatChannelPinnedMessagesDrawerFragment$key = {
  readonly " $data"?: ChatChannelPinnedMessagesDrawerFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelPinnedMessagesDrawerFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelPinnedMessagesDrawerFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ChatChannelPinnedMessagesListFragment"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};

(node as any).hash = "f41539806df3275edc7b1036d2975dfb";

export default node;
