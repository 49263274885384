import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoIconButton, DiscoText } from "@disco-ui"
import { lighten } from "@material-ui/core"
import classNames from "classnames"
import { LinkPreview } from "stream-chat-react"

export declare type Props = {
  link: LinkPreview
  removeLink: () => void
}

export const CustomChatChannelLinkAttachment = ({ link, removeLink }: Props) => {
  const classes = useStyles()
  if (!link.image_url && !link.title && !link.text) {
    return null
  }

  return (
    <div className={classes.linkAttachment}>
      {link.image_url && (
        <img src={link.image_url} alt={""} className={classes.linkIcon} />
      )}
      <div className={classes.linkText}>
        {link.title && (
          <DiscoText variant={"body-md-600"} color={"text.primary"} truncateText={1}>
            {link.title}
          </DiscoText>
        )}
        {link.text && (
          <DiscoText variant={"body-xs"} color={"text.secondary"} truncateText={1}>
            {link.text}
          </DiscoText>
        )}
      </div>

      <DiscoIconButton
        onClick={removeLink}
        height={20}
        width={20}
        classes={{ root: classNames(classes.delete) }}
        svgStyles={{ height: 10, width: 10 }}
        color={"white"}
      >
        <CloseIcon className={classes.icon} />
      </DiscoIconButton>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  linkAttachment: {
    width: "400px",
    height: "80px",
    flexShrink: 0,
    border: "1px solid",
    borderColor: theme.palette.divider,
    boxShadow: theme.palette.groovyDepths.xs,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.background.paper,
    display: "flex",
    alignItems: "center",
    position: "relative",
    padding: theme.spacing(1.5),
  },

  linkIcon: {
    width: "48px",
    height: "48px",
    marginRight: theme.spacing(1.5),
    borderRadius: theme.measure.borderRadius.medium,
    objectFit: "contain",
    objectPosition: "center",
  },
  linkText: {
    display: "flex",
    flexDirection: "column",
    minWidth: 0,
  },
  icon: {
    color: "white",
  },
  delete: {
    borderRadius: "50%",
    backgroundColor: theme.palette.groovy.neutral[500],
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translateY(-50%) translateX(50%)",

    "&:hover": {
      backgroundColor: lighten(theme.palette.groovy.neutral[500], 0.2),
    },

    "& span": {
      width: "unset",

      "& svg": {
        color: theme.palette.common.white,
      },
    },
  },
}))
