import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoButton } from "@disco-ui"
import React from "react"
import {
  DefaultStreamChatGenerics,
  SendButtonProps,
  useMessageInputContext,
} from "stream-chat-react"

type Props = SendButtonProps<DefaultStreamChatGenerics> & {
  sendMessage?: (e: React.BaseSyntheticEvent) => void
}

function ChatChannelDetailFooterSendButton({ sendMessage }: Props) {
  const classes = useStyles()
  const { disabled, handleSubmit } = useMessageInputContext()

  /* Show text if on mobile, or in a thread not on mobile */
  return (
    <DiscoButton
      data-testid={"ChatChannelDetailFooterSendButton.button"}
      onClick={(e) => sendChannelMessage(e)}
      className={classes.iconButton}
      disabled={disabled}
    >
      {"Send Now"}
    </DiscoButton>
  )
  function sendChannelMessage(e: React.BaseSyntheticEvent) {
    if (sendMessage) {
      sendMessage(e)
    } else {
      handleSubmit(e)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.hover,
      "& path": {
        color: theme.palette.primary.contrastText,
      },
    },
    "& path": {
      color: theme.palette.primary.light,
    },
  },
}))

export default ChatChannelDetailFooterSendButton
