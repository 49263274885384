import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import FileIcon from "@/core/ui/iconsax/linear/document-text.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import ChatChannelQuotedMessagePreview from "@components/chat/channel/detail/quoted-message/ChatChannelQuotedMessagePreview"
import { ChatChannelEditMessageInputQuery } from "@components/chat/channel/edit/__generated__/ChatChannelEditMessageInputQuery.graphql"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import MessageAttachContentBlockButton from "@components/chat/channel/message-input/MessageAttachContentBlockButton"
import MessageInputAutoComplete from "@components/chat/channel/message-input/MessageInputAutoComplete"
import { ChatChannelUploadsPreview } from "@components/chat/channel/uploads/ChatChannelUploadsPreview"
import { CHAT_CHANNEL_MAX_ROWS } from "@components/chat/channel/util/chatChannelConstants"
import { getUrlAttachments } from "@components/chat/util/chatUtils"
import { displayErrorToast, displayWarningToast } from "@components/toast/ToastProvider"
import { DiscoButton, DiscoTooltip } from "@disco-ui"
import classNames from "classnames"
import { ClipboardEvent, useState } from "react"
import { FileUploadButton, ImageDropzone } from "react-file-utils"
import { useLazyLoadQuery } from "react-relay"
import { graphql } from "relay-runtime"
import { useChannelStateContext, useMessageInputContext } from "stream-chat-react"
import { v4 as uuidv4 } from "uuid"

const ChatChannelEditMessageInput = () => {
  const { acceptedFiles, multipleUploads, quotedMessage } = useChannelStateContext()

  const {
    clearEditingState,
    cooldownRemaining,
    handleSubmit,
    isUploadEnabled,
    maxFilesLeft,
    uploadNewFiles,
    setText,
    text,
    insertText,
    upsertAttachments,
    message,
    handleChange,
    attachments,
    onPaste,
  } = useMessageInputContext()
  const classes = useStyles()
  const activeOrganization = useActiveOrganization()!
  const [currentInput, setCurrentInput] = useState(message?.text)
  const { organization } = useLazyLoadQuery<ChatChannelEditMessageInputQuery>(
    graphql`
      query ChatChannelEditMessageInputQuery($id: ID!) {
        organization: node(id: $id) {
          ... on Organization {
            products(type: "course") {
              edges {
                node {
                  id
                  name
                  slug
                }
              }
            }
          }
        }
      }
    `,
    {
      id: activeOrganization.id,
    },
    {
      fetchPolicy: "store-and-network",
    }
  )

  const handleInputChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentInput(event.target.value)
    handleChange(event)
  }

  return (
    <div className={classes.container}>
      <div
        className={classNames(classes.root)}
        data-testid={"ChatChannelEditMessageInput.container"}
      >
        <ImageDropzone
          accept={acceptedFiles}
          disabled={!isUploadEnabled || maxFilesLeft === 0 || !!cooldownRemaining}
          handleFiles={uploadNewFiles}
          maxNumberOfFiles={maxFilesLeft}
          multiple={multipleUploads}
        >
          {/* Quote Preview */}
          {quotedMessage && (
            <ChatChannelQuotedMessagePreview quotedMessage={quotedMessage} />
          )}

          <div className={"str-chat__input-flat-wrapper"}>
            <div className={"str-chat__input-flat--textarea-wrapper"}>
              {isUploadEnabled && (
                <ChatChannelUploadsPreview
                  previewLinksToHide={
                    Array.isArray(message?.previewLinksToHide)
                      ? message?.previewLinksToHide
                      : undefined
                  }
                />
              )}
              {/* Message */}
              <div className={classes.input}>
                <div className={classes.inputMessage}>
                  <MessageInputAutoComplete
                    onPaste={handlePaste}
                    rows={1}
                    maxRows={CHAT_CHANNEL_MAX_ROWS}
                    grow
                    onChange={handleInputChange}
                  />

                  {/* Actions */}
                  <div className={classes.inputActions}>
                    <MessageAttachContentBlockButton
                      setText={setText}
                      attachments={attachments}
                      text={text}
                      classes={classes.iconButton}
                    />
                    {/* Emoji Picker */}
                    <ChatChannelEmojiPickerDropdown
                      onEmojiSelect={onSelectEmoji}
                      classes={classes.iconButton}
                      useNativeEmoji
                    />
                    {isUploadEnabled && !cooldownRemaining && (
                      <DiscoTooltip content={"Attach Files"}>
                        <div data-testid={"fileinput"} className={classes.fileInput}>
                          <FileUploadButton
                            accepts={acceptedFiles}
                            disabled={maxFilesLeft === 0}
                            handleFiles={handleFileUpload}
                            multiple={multipleUploads}
                          >
                            <FileIcon />
                          </FileUploadButton>
                        </div>
                      </DiscoTooltip>
                    )}
                  </div>
                </div>
                <div className={classes.buttons}>
                  {/* Cancel */}
                  <DiscoButton
                    color={"grey"}
                    variant={"outlined"}
                    onClick={clearEditingState}
                  >
                    {"Cancel"}
                  </DiscoButton>

                  {/* Save */}
                  {!cooldownRemaining && (
                    <DiscoButton onClick={saveEditedMessage}>{"Save Edits"}</DiscoButton>
                  )}
                </div>
              </div>
            </div>
          </div>
        </ImageDropzone>
      </div>
    </div>
  )
  function handleFileUpload(files: FileList | File[]) {
    // maximum 10 attachments per message
    if (attachments.length < 10) {
      uploadNewFiles(files)
    } else {
      displayWarningToast({
        message: "You can only upload 10 attachments at a time.",
        testid: `overflow-attachments-toast`,
      })
    }
  }

  function onSelectEmoji(emoji: string) {
    insertText(emoji)
  }

  function saveEditedMessage(submitEvent: React.BaseSyntheticEvent) {
    if (currentInput?.trim() === "" && attachments.length === 0) {
      displayErrorToast("Cannot save an empty message")
    } else {
      handleSubmit(submitEvent)
    }
  }

  function handlePaste(event: ClipboardEvent<HTMLTextAreaElement>) {
    onPaste(event)
    const pastedText = event.clipboardData.getData("Text")
    const products = Relay.connectionToArray(organization?.products)
    const urlAttachments = getUrlAttachments(pastedText, activeOrganization, products)

    if (urlAttachments && urlAttachments.length > 0) {
      const localUrlAttachments = urlAttachments.map((attachment) => ({
        ...attachment,
        localMetadata: {
          id: uuidv4(),
        },
      }))

      upsertAttachments(localUrlAttachments)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(1),
    position: "relative",
  },
  root: {
    "& .rfu-dropzone": {
      "& .rfu-dropzone__notifier": {
        display: "none", // Hide by default
      },
      "&.rfu-dropzone--accept .rfu-dropzone__notifier": {
        display: "flex",
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.95)",
        zIndex: theme.zIndex.raise3,
        alignItems: "center",
        justifyContent: "center",
        border: `2px dashed ${theme.palette.primary.main}`,
        borderRadius: theme.measure.borderRadius.medium,
        "& .rfu-dropzone__inner": {
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: theme.spacing(2),
          "& svg": {
            color: theme.palette.primary.main,
          },
          "& p": {
            color: theme.palette.text.primary,
            margin: 0,
            ...theme.typography["body-md"],
          },
        },
      },
    },
    "& [data-testid='fileinput']": {
      "& .rfu-file-upload-button": {
        height: "20px",
        display: "flex",
        alignItems: "center",
        "& label": {
          margin: 0,
          padding: 0,
          cursor: "pointer",
          "& input": {
            display: "none",
          },
          "& svg": {
            fill: "transparent",
            color:
              theme.palette.type === "dark"
                ? theme.palette.groovy.grey[100]
                : theme.palette.groovy.grey[400],
            opacity: 1,
            width: "20px",
            height: "20px",
          },
        },
      },
    },
    "&.str-chat__input-flat": {
      backgroundColor: theme.palette.background.paper,
      display: "list-item",
    },
    "&.str-chat__input-flat-wrapper": {
      display: "block",
      width: "100%",
    },
    "& .str-chat__input-flat--textarea-wrapper": {
      maxWidth: "100%",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column-reverse",
      },
    },
    "& .str-chat__textarea": {
      display: "flex",
      alignItems: "center",
      width: "100%",

      "& > textarea": {
        width: "100%",
        background: theme.palette.background.paper,
        padding: theme.spacing(1, 2),
        borderRadius: theme.measure.borderRadius.medium,
        color: theme.palette.text.primary,
        border: `2px solid ${theme.palette.background.paper}`,
        ...theme.typography["body-sm"],

        "&:focus": {
          backgroundColor:
            theme.palette.type === "dark"
              ? theme.palette.groovy.grey
              : theme.palette.common.white,
          border: "none",
          boxShadow: `inset 0 0 0 2px ${theme.palette.groovy.grey[200]}`,
          ...theme.typography["body-sm"],
          color: theme.palette.text.secondary,
        },

        "&::placeholder": {
          ...theme.typography["body-sm"],
          color: theme.palette.groovy.grey[300],
          whiteSpace: "nowrap",
          overflow: "hidden",
          maxHeight: "40px",
        },
      },
    },
  },
  input: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "space-between",
    flexDirection: "column",
    width: "100%",
    gap: theme.spacing(1),
    marginTop: theme.spacing(1),
  },
  inputMessage: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    justifyContent: "space-between",
    background: theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.medium,
  },
  inputActions: {
    display: "flex",
    alignSelf: "flex-end",
    zIndex: theme.zIndex.raise2,
    padding: "5px",
  },
  iconButton: {
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.grey[100]
          : theme.palette.groovy.grey[400],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(0.5),
  },
  fileInput: {
    width: "30px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& .rfu-file-upload-button": {
      display: "flex",
      alignItems: "center",
      "& label": {
        display: "flex",
        alignItems: "center",
        margin: 0,
        "& svg": {
          display: "flex",
        },
      },
    },
  },
}))

export default ChatChannelEditMessageInput
