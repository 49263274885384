import makeUseStyles from "@assets/style/util/makeUseStyles"
import { ClassNameMap } from "@material-ui/core/styles/withStyles"
import classNames from "classnames"
import { useEffect, useState } from "react"
import { Swiper as SwiperType, SwiperOptions } from "swiper"
import "swiper/css"
import { register, SwiperContainer } from "swiper/element/bundle"

// Register swiper web components once on app load
register()

/**
 * React Swiper Component using Swiper Web Components
 * initialize() or update() are called in useEffect on `config` param change
 */
export type SwiperProps = {
  children: React.ReactNode
  className?: string
  classes?: Partial<ClassNameMap<"swiper">>
  swiperPaginationTop?: number
  config: {
    navigation?: {
      nextEl: HTMLElement | null
      prevEl: HTMLElement | null
    }
    pagination?: boolean
    breakpoints?: {
      [k in 0 | 600 | 1280 | 1520]: Pick<SwiperOptions, "slidesPerView">
    }
    totalCount: number
    currentSliceSize: number
    centeredSlides?: boolean
    setParentCarouselState?: React.Dispatch<React.SetStateAction<SwiperType | null>>
    // Specify a single direction for navigation. Helpful when controlling navigation
    // with a component not within the carousel.
    partialNavigation?: boolean
  } & Partial<Pick<SwiperOptions, "initialSlide" | "slidesPerView">>
}

export default function Swiper({
  children,
  className,
  classes: customClasses,
  swiperPaginationTop = 0,
  config: {
    initialSlide = 0,
    slidesPerView,
    centeredSlides = true,
    breakpoints,
    navigation,
    pagination = false,
    totalCount,
    currentSliceSize,
    setParentCarouselState,
    partialNavigation = false,
  },
}: SwiperProps) {
  const [swiperEl, setSwiperEl] = useState<SwiperContainer | null>(null)
  const hasSwiperElRef = !!swiperEl

  const classes = useStyles({
    swiperPaginationTop,
  })

  useEffect(() => {
    // elements required for initialization
    if (!hasSwiperElRef) return

    if (!partialNavigation && navigation && (!navigation.nextEl || !navigation.prevEl))
      return

    // Assign all parameters to Swiper element
    Object.assign<SwiperContainer, SwiperOptions>(swiperEl, {
      initialSlide,
      navigation,
      // if we set slidesPerView, don't pass breakpoints
      ...(slidesPerView === undefined
        ? {
            breakpoints: {
              // theme.breakpoints.up("xs")
              0: {
                slidesPerView: breakpoints ? breakpoints[0].slidesPerView : 1,
              },
              // theme.breakpoints.up("sm")
              600: {
                slidesPerView: breakpoints ? breakpoints[600].slidesPerView : 1.5,
              },
              // theme.breakpoints.up("lg")
              1280: {
                slidesPerView: breakpoints ? breakpoints[1280].slidesPerView : 3.5,
              },
              // theme.breakpoints.up("xl")
              1520: {
                slidesPerView: breakpoints ? breakpoints[1520].slidesPerView : 3.5,
              },
            },
          }
        : { slidesPerView }),
      // allow centered or uncentered slides
      centeredSlides,
      /**
       * Defaults - should not require configuration
       */
      slidesPerGroup: 1,
      centeredSlidesBounds: true,
      watchSlidesProgress: true,
      centerInsufficientSlides: false,
      // simulating touch events on desktop causes issues with react-dnd
      simulateTouch: false,
      touchEventsTarget: "container",
      // still not enough support for container queries - ex: css-in-js no way to do container queries, so use window for now
      breakpointsBase: "window",
      // subscribe to relevant update events
      on: {
        // watch breakpoints, progress, and length so we can handle first and last hint at edges
        breakpoint: handleSwiperChange,
        progress: handleSwiperChange,
        slidesLengthChange: handleSwiperChange,
        // events used by DiscoCarousel parent component
        activeIndexChange: handleSwiperChange,
        reachBeginning: handleSwiperChange,
        reachEnd: handleSwiperChange,
      },
    })

    if (pagination) {
      swiperEl.pagination = {
        clickable: true,
      }
    }

    /**
     * Initialize Swiper - once initialized, the Swiper instance is available on the swiper element
     * note: some parameters can only be set on initialization, or will not be updated when calling `update()`
     * check the documentation when adding new params, see: https://swiperjs.com/swiper-api
     * @see https://swiperjs.com/swiper-api#parameters
     * and @see https://swiperjs.com/swiper-api#modules
     */
    if (!swiperEl.swiper) return swiperEl.initialize()

    /**
     * Update Swiper on config change
     * some modules may require call to their own update method
     */
    swiperEl.swiper.update()

    // Re-init navigation on update in case navigation button refs have changed
    if (navigation) {
      swiperEl.swiper.navigation.destroy()
      swiperEl.swiper.navigation.init()
      swiperEl.swiper.navigation.update()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    slidesPerView,
    breakpoints,
    navigation,
    pagination,
    totalCount,
    currentSliceSize,
    hasSwiperElRef,
  ])

  return (
    <swiper-container
      ref={(el: SwiperContainer) => setSwiperEl(el)}
      // custom elements use class not className, see: https://react.dev/reference/react-dom/components#custom-html-elements
      class={classNames(classes.swiper, className, customClasses?.swiper)}
      // Swiper needs tø be manually initialized
      init={false}
      // needed so swiper touch events don't conflict with react-dnd touch events
      events-prefix={"swiper"}
      // Modules should be initialized here, not assigned to the swiper element in useEffect
      // the latter causes a bug where the module is initialized twice
      navigation={Boolean(navigation)}
      pagination={pagination}
    >
      {children}
    </swiper-container>
  )

  function handleSwiperChange(updatedSwiper: SwiperContainer["swiper"]) {
    // track changes for internal state
    setSwiperEl((el) => ({ ...el!, swiper: updatedSwiper }))
    // also track changes for states we expose to parent
    setParentCarouselState?.({ ...updatedSwiper })
  }
}

type StyleProps = {
  swiperPaginationTop: number
}

const useStyles = makeUseStyles((theme) => ({
  swiper: ({ swiperPaginationTop }: StyleProps) => ({
    // Variable used to set position of pagination dots
    "--swiper-pagination-top": `${swiperPaginationTop}px`,

    "&:host .swiper-wrapper": {
      alignItems: "stretch",
    },
    "& swiper-slide": {
      height: "auto",
      display: "flex",
      flexDirection: "column",
      "& > *": {
        height: "100%",
        // minimum margin needed for cards that may use groovyDepths.boxShadow on hover
        margin: theme.spacing(1, 1, 2),
      },
    },
  }),
}))
