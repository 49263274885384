import { StreamChatUserData } from "@/core/context/StreamChatContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { DiscoText } from "@disco-ui"
import { FC, memo } from "react"
import { UserItemProps } from "stream-chat-react"

/**
 * UI component for mentions rendered in suggestion list
 */
const UnMemoizedMentionUserItem: FC<UserItemProps & { selected?: boolean }> = (props) => {
  const { entity, selected } = props
  const classes = useStyles()

  const hasEntity = Object.keys(entity).length
  const itemParts = entity?.itemNameParts

  const user = entity as StreamChatUserData

  return (
    <div className={classes.userItem}>
      <UserAvatar
        user={{
          id: user.disco_user_id,
          first_name: user.first_name,
          last_name: user.last_name,
          avatar: user.avatar,
          name: user.name,
          is_test_user: user.is_disco_test_user || false,
        }}
        size={32}
        testid={"MentionUserAvatar"}
        className={classes.avatar}
        placeholderClassName={classes.avatar}
      />
      <DiscoText
        color={selected ? "common.white" : "common.black"}
        data-testid={"user-item-name"}
      >
        {hasEntity ? itemParts.parts.join("") : null}
      </DiscoText>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  userItem: {
    display: "flex",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
  },
}))

const MentionUserItem = memo(UnMemoizedMentionUserItem)

export default MentionUserItem
