import {
  ActiveOrganizationContextValue,
  useActiveOrganization,
} from "@/core/context/ActiveOrganizationContext"
import {
  extractChatChannelId,
  extractContentUsageId,
  extractOccurrenceId,
  extractProductSlug,
  getProductIdBySlug,
  isDiscoDomain,
  isProductUrl,
} from "@/core/route/util/routeUtils"
import Relay from "@/relay/relayUtils"
import { chatUtilsChatQuery } from "@components/chat/util/__generated__/chatUtilsChatQuery.graphql"
import { chatUtilsContentUsageQuery } from "@components/chat/util/__generated__/chatUtilsContentUsageQuery.graphql"
import { chatUtilsOccurrenceQuery } from "@components/chat/util/__generated__/chatUtilsOccurrenceQuery.graphql"
import { chatUtilsProductQuery } from "@components/chat/util/__generated__/chatUtilsProductQuery.graphql"
import { chatUtils_OrganizationMembershipStreamConnectionQuery } from "@components/chat/util/__generated__/chatUtils_OrganizationMembershipStreamConnectionQuery.graphql"
import { DATE_FORMAT } from "@utils/time/timeConstants"
import {
  formatDateWithOptions,
  getDifferenceInDaysBetweenDates,
} from "@utils/time/timeUtils"
import { graphql, useLazyLoadQuery } from "react-relay"
import { Attachment } from "stream-chat"
import {
  DefaultStreamChatGenerics,
  GroupStyle,
  MessageActionsArray,
  StreamMessage,
} from "stream-chat-react"
const MAX_TIME_BETWEEN_MESSAGES = 3600000

export const MESSAGE_ACTIONS: MessageActionsArray<string> = [
  "mute",
  "edit",
  "pin",
  "quote",
  "reply",
  "delete",
  "react",
]

/** Set the group styles for a message depending on it's position in a group */
export function groupStyles(
  message: StreamMessage,
  previousMessage: StreamMessage,
  nextMessage: StreamMessage,
  noGroupByUser: boolean
): GroupStyle {
  let timeBetweenGroupedMessages =
    message.created_at && previousMessage?.created_at
      ? new Date(message.created_at).getTime() -
        new Date(previousMessage.created_at).getTime()
      : 0
  const isTop =
    !previousMessage ||
    previousMessage?.user?.id !== message?.user?.id ||
    timeBetweenGroupedMessages > MAX_TIME_BETWEEN_MESSAGES

  timeBetweenGroupedMessages =
    message.created_at && nextMessage?.created_at
      ? new Date(nextMessage.created_at).getTime() -
        new Date(message.created_at).getTime()
      : 0

  const isBottom =
    !nextMessage ||
    nextMessage?.user?.id !== message?.user?.id ||
    timeBetweenGroupedMessages > MAX_TIME_BETWEEN_MESSAGES
  const isMiddle = !isTop && !isBottom

  if (noGroupByUser) return "single"
  if (isTop) return "top"
  if (isBottom) return "bottom"
  if (isMiddle) return "middle"
  return "single"
}

/** Return the organization member's Stream connection if there is one */
export function useOrganizationMembershipStreamConnection() {
  const activeOrganization = useActiveOrganization()!
  const { organizationMembership } =
    useLazyLoadQuery<chatUtils_OrganizationMembershipStreamConnectionQuery>(
      graphql`
        query chatUtils_OrganizationMembershipStreamConnectionQuery($id: ID!) {
          organizationMembership: node(id: $id) {
            ... on OrganizationMembership {
              streamChatUserId
            }
          }
        }
      `,
      {
        id: activeOrganization.viewerMembership?.id || "",
      }
    )

  if (!organizationMembership) return null
  return organizationMembership.streamChatUserId
}

export async function getProduct(productSlug: string, organizationSlug: string) {
  const product = await Relay.runQuery<chatUtilsProductQuery>(
    graphql`
      query chatUtilsProductQuery($productSlug: String!, $organizationSlug: String!) {
        product(slug: $productSlug, organizationSlug: $organizationSlug) {
          name
          id
        }
      }
    `,
    {
      productSlug,
      organizationSlug,
    },
    { fetchPolicy: "store-or-network" }
  )
  return product
}
export async function getOccurrence(occurrenceId: string) {
  const occurrence = await Relay.runQuery<chatUtilsOccurrenceQuery>(
    graphql`
      query chatUtilsOccurrenceQuery($id: ID!) {
        node(id: $id) {
          ... on Occurrence {
            id
            name
          }
        }
      }
    `,
    {
      id: occurrenceId,
    },
    { fetchPolicy: "store-or-network" }
  )
  return occurrence
}

export async function getContentUsage(contentUsageId: string) {
  const contentUsage = await Relay.runQuery<chatUtilsContentUsageQuery>(
    graphql`
      query chatUtilsContentUsageQuery($id: ID!) {
        node(id: $id) {
          ... on ContentUsage {
            id
            content {
              name
            }
          }
        }
      }
    `,
    {
      id: contentUsageId,
    },
    { fetchPolicy: "store-or-network" }
  )
  return contentUsage
}

export async function getChatChannel(chatChannelId: string) {
  const channel = await Relay.runQuery<chatUtilsChatQuery>(
    graphql`
      query chatUtilsChatQuery($id: ID!) {
        node(id: $id) {
          ... on ChatChannel {
            id
            app {
              customAppTitle
            }
          }
        }
      }
    `,
    {
      id: chatChannelId,
    },
    { fetchPolicy: "store-or-network" }
  )
  return channel
}

function findUrlsInString(text: string) {
  const markdownUrlPattern = /\[([^\]]+)\]\(([^)]+)\)/g
  // Source: https://stackoverflow.com/questions/6038061/regular-expression-to-find-urls-within-a-string
  const urlPattern =
    /(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:\\/~+#-]*[\w@?^=%&\\/~+#-])/g

  // Remove markdown URLs, ie. [text](url)
  const noMarkdownText = text.replaceAll(markdownUrlPattern, "")

  // Find and return all URLs
  return noMarkdownText.match(urlPattern) || []
}

export async function processDiscoUrls(
  messageText: string,
  organization: ActiveOrganizationContextValue
) {
  const matches = findUrlsInString(messageText)
  let newMessage = messageText
  const replacements = new Map()

  if (!matches) return messageText
  for (const match of matches) {
    const isValidDomain =
      isDiscoDomain(match) || match.includes(organization.primaryDomain)
    // Skip if already processed or not a Disco domain or the organization's primary domain
    if (replacements.has(match) || !isValidDomain) continue

    const occurenceGlobalId = extractOccurrenceId(match)
    if (occurenceGlobalId) {
      const occurrence = await getOccurrence(occurenceGlobalId)
      if (occurrence?.node?.name) {
        replacements.set(match, `[${occurrence.node.name}](${match})`)
        continue
      }
    }

    const contentUsageId = extractContentUsageId(match)
    if (contentUsageId) {
      const contentUsageGlobalId = Relay.toGlobalId("ContentUsage", contentUsageId)
      const contentUsage = await getContentUsage(contentUsageGlobalId)
      if (contentUsage?.node?.content?.name) {
        replacements.set(match, `[${contentUsage.node.content.name}](${match})`)
        continue
      }
    }

    const chatChannelId = extractChatChannelId(match)
    if (chatChannelId) {
      const chatChannel = await getChatChannel(chatChannelId)
      if (chatChannel?.node?.app?.customAppTitle) {
        replacements.set(match, `[#${chatChannel.node.app.customAppTitle}](${match})`)
        continue
      }
    }

    if (isProductUrl(match)) {
      const productSlug = extractProductSlug(match)
      if (productSlug) {
        const product = await getProduct(productSlug, organization.slug)
        if (product?.product?.name) {
          replacements.set(match, `[${product.product.name}](${match})`)
          continue
        }
      }
    }
  }

  replacements.forEach((replacementText, originalText) => {
    newMessage = newMessage.split(originalText).join(replacementText)
  })

  return newMessage
}
export function cleanPreviewAttachments(
  messageAttachments: Attachment<DefaultStreamChatGenerics>[] | undefined
) {
  const cleanAttachments = messageAttachments
  const discoCoRegex = /^(https?:\/\/)?(www\.)?disco\.co/ // Regex to match URLs from disco.co
  if (cleanAttachments) {
    // Iterate through list of message attachments to remove auto added previews for specific urls in steamchat messages
    for (let i = cleanAttachments.length - 1; i >= 0; i--) {
      const attachment = cleanAttachments[i]
      if (!attachment.og_scrape_url || !attachment.title_link) continue // skip if attachment does not have scrape or location url
      const titleLinkMatches = discoCoRegex.test(attachment.title_link)
      const ogScrapeUrlMatches = discoCoRegex.test(attachment.og_scrape_url)
      if (
        (titleLinkMatches && !ogScrapeUrlMatches) || // Attachments that link to disco.co but are from specific sections of a community
        extractOccurrenceId(attachment.og_scrape_url) !== null || // Attachments generated from occurrence links
        extractContentUsageId(attachment.og_scrape_url) !== null || // Attachments generated from content usage links
        extractChatChannelId(attachment.og_scrape_url) !== null || // Attachments generated from chatchannel links
        isProductUrl(attachment.og_scrape_url) // Attachments generated from product links
      ) {
        cleanAttachments.splice(i, 1) // Remove from list of attachments
      }
    }
  }

  return cleanAttachments
}

export function getUrlAttachments(
  messageText: string,
  organization: ActiveOrganizationContextValue,
  products: {
    readonly id: string
    readonly name: string
    readonly slug: string
  }[]
) {
  const attachments: Attachment<DefaultStreamChatGenerics>[] = []

  // Create array of attachments from internal urls found in message text
  const urlRegex = /\bhttps?:\/\/\S+\b/g
  const foundUrls = messageText.match(urlRegex) || []

  for (const url of foundUrls) {
    const isPrimaryDomain = url.includes(organization.primaryDomain)
    if (!isDiscoDomain(url) && !isPrimaryDomain) return

    const occurenceGlobalId = extractOccurrenceId(url)
    if (
      occurenceGlobalId &&
      !attachments?.some((attachment) => attachment.text === occurenceGlobalId)
    ) {
      attachments?.push({
        text: occurenceGlobalId,
        type: "occurrence",
      })
      continue
    }

    const contentUsageId = extractContentUsageId(url)
    const contentUsageGlobalId = contentUsageId
      ? Relay.toGlobalId("ContentUsage", contentUsageId)
      : null
    if (
      contentUsageGlobalId &&
      !attachments?.some((attachment) => attachment.text === contentUsageGlobalId)
    ) {
      attachments?.push({
        text: contentUsageGlobalId,
        type: "contentUsage",
      })
      continue
    }

    const productSlug = extractProductSlug(url)
    const isProductHome = isProductUrl(url)
    const productId = productSlug ? getProductIdBySlug(productSlug, products) : null
    if (
      productId &&
      isProductHome &&
      !attachments?.some((attachment) => attachment.text === productId)
    ) {
      attachments?.push({
        text: productId,
        type: "product",
      })
    }
  }
  return attachments
}

export function formatStreamMessageDate(createdAt: Date | string | undefined): {
  tooltip: string
  display: string
} | null {
  if (!createdAt) return null
  const date = typeof createdAt === "string" ? new Date(createdAt) : createdAt
  const str = typeof createdAt === "string" ? createdAt : createdAt.toDateString()

  const tooltip = formatDateWithOptions({
    format: DATE_FORMAT.DATE_WITH_SHORT_TIME_FORMAT_AT,
    shouldShiftDateToCompensateForTimezone: false,
  })(date)

  const display =
    getDifferenceInDaysBetweenDates(new Date().toDateString(), str) === 1
      ? "Yesterday"
      : formatDateWithOptions({
          format: DATE_FORMAT.DEFAULT_MONTH_AND_DAY,
          shouldShiftDateToCompensateForTimezone: false,
        })(date)

  return { tooltip, display }
}

/** Remove HTML tags and new lines */
export function sanitizeMessage(text: string) {
  return text.replace(/<[^>]*>?/gm, "").replace(/\n/g, "")
}
