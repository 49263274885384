import { ChatChannelPageContentParams } from "@/chat/channel/page/content/ChatChannelPageContent"
import { GlobalID } from "@/relay/RelayTypes"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import ChatChannelProfileListDrawer from "@components/chat/channel/profile-list-drawer/ChatChannelProfileListDrawer"
import {
  OverridableDiscoButton,
  OverridableDiscoButtonChildren,
} from "@disco-ui/button/OverridableDiscoButton"
import React, { useState } from "react"
import { useParams } from "react-router-dom"

function OpenChatChannelProfileListButton({
  children,
  channelId: propsChannelId,
}: {
  children: OverridableDiscoButtonChildren
  channelId: GlobalID
}) {
  const classes = useStyles()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const { channelId: paramsChannelId } = useParams<ChatChannelPageContentParams>()
  const channelId = propsChannelId || paramsChannelId

  return (
    <>
      <OverridableDiscoButton
        onClick={handleClick}
        color={"transparent"}
        className={classes.button}
      >
        {children}
      </OverridableDiscoButton>

      {channelId && isDrawerOpen && (
        <ChatChannelProfileListDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          channelId={channelId}
        />
      )}
    </>
  )

  function handleClick(e: React.MouseEvent) {
    e.stopPropagation()
    setIsDrawerOpen(true)
  }
}

const useStyles = makeUseStyles({
  button: {
    paddingRight: 0,
    paddingLeft: 0,
  },
})

export default OpenChatChannelProfileListButton
