/**
 * @generated SignedSource<<d508af6d5bdccbd0a934078eb4c28ffd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type BadgeKind = "emoji" | "icon" | "upload" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type CommunityBadgeFragment$data = {
  readonly badge: {
    readonly color: string | null;
    readonly icon: string | null;
    readonly id: string;
    readonly kind: BadgeKind;
    readonly " $fragmentSpreads": FragmentRefs<"BadgeFragment">;
  } | null;
  readonly name: string;
  readonly " $fragmentType": "CommunityBadgeFragment";
};
export type CommunityBadgeFragment$key = {
  readonly " $data"?: CommunityBadgeFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommunityBadgeFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommunityBadgeFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Badge",
      "kind": "LinkedField",
      "name": "badge",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "icon",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "color",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "BadgeFragment"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Organization",
  "abstractKey": null
};

(node as any).hash = "3a8c5543551fa6e075262be53ab9ffe6";

export default node;
