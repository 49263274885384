import { useStreamChannel } from "@/core/context/StreamChatContext"
import ROUTE_NAMES from "@/core/route/util/routeNames"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import CommunitySidebarItem from "@/organization/common/sidebar/CommunitySidebarItem"
import Relay from "@/relay/relayUtils"
import ProfileAvatar from "@/user/common/avatar/ProfileAvatar"
import { TextVariantWithModifiers } from "@assets/style/appMuiTheme"
import styleIf from "@assets/style/util/styleIf"
import AvatarPlaceholder from "@components/avatar/placeholder/AvatarPlaceholder"
import ChatChannelAvatarStack from "@components/chat/channel/detail/avatar-stack/ChatChannelAvatarStack"
import { DirectMessagesListItemFragment$key } from "@components/chat/channel/list/__generated__/DirectMessagesListItemFragment.graphql"
import useStreamTotalUnreadCount from "@components/chat/channel/util/useStreamTotalUnreadCount"
import { formatStreamMessageDate } from "@components/chat/util/chatUtils"
import CountBadge from "@components/square-count-badge/CountBadge"
import NotificationBadge from "@components/square-count-badge/NotificationBadge"
import {
  DiscoSideBarItemSkeleton,
  DiscoText,
  DiscoTextSkeleton,
  DiscoTooltip,
} from "@disco-ui"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import { useTheme } from "@material-ui/core"
import { Skeleton } from "@material-ui/lab"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"
import { useFragment } from "react-relay"
import { generatePath, useHistory, useLocation } from "react-router-dom"
import { graphql } from "relay-runtime"
import { FormatMessageResponse } from "stream-chat"
import { DefaultStreamChatGenerics, useChatContext } from "stream-chat-react"

type Props = TestIDProps & {
  chatChannelKey: DirectMessagesListItemFragment$key
  variant: "sidebar" | "page"
}

function DirectMessagesListItem(props: Props) {
  const { chatChannelKey, variant, testid = "DirectMessagesListItem" } = props

  const chatChannel = useFragment<DirectMessagesListItemFragment$key>(
    graphql`
      fragment DirectMessagesListItemFragment on ChatChannel {
        id
        kind
        externalChannelId
        app {
          customAppTitle
        }
        chatChannelMembers(excludeViewer: true) {
          edges {
            node {
              id
              user {
                fullName
                ...ProfileAvatarStackFragment @relay(mask: false)
              }
            }
          }
          totalCount
        }
      }
    `,
    chatChannelKey
  )
  const streamChannel = useStreamChannel(chatChannel.externalChannelId, true)
  const unreadCount = useStreamTotalUnreadCount([chatChannel.externalChannelId])
  const isUnread = unreadCount > 0
  const { client: stream } = useChatContext()
  const isOnline = checkMembersOnline()
  const location = useLocation()
  const isSelected = location.pathname.endsWith(chatChannel.id)
  const classes = useStyles({
    variant,
    isUnread,
    isSelected,
  })
  const theme = useTheme()
  const history = useHistory()

  /**
   * If there are no other members besides the viewer, do not show the direct message item
   * It is possible to have a DM channel with only one member if the other members have left or been removed the community
   */
  if (chatChannel.chatChannelMembers.totalCount === 0) return null
  if (!streamChannel) return null

  // From the lib JS doc lastMessage() can possiblly return undefined, so we need to check it
  const lastMessage: FormatMessageResponse<DefaultStreamChatGenerics> | undefined =
    streamChannel.lastMessage()

  const users = Relay.connectionToArray(chatChannel.chatChannelMembers).map((m) => m.user)
  const chatChannelName = users.map((u) => u.fullName).join(", ")
  const pageVariant = variant === "page"

  if (!pageVariant) {
    return (
      <CommunitySidebarItem
        to={generatePath(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL, {
          channelId: chatChannel.id,
        })}
        rightContent={isUnread && <CountBadge count={unreadCount} />}
        testid={`${testid}.sidebar-${chatChannelName}`}
        className={"fs-exclude"}
        leftIcon={
          <div className={classes.relativeParent}>
            {users.length > 1 ? (
              <AvatarPlaceholder
                size={24}
                text={users.length}
                backgroundColor={
                  theme.palette.type === "light"
                    ? theme.palette.groovy.neutral[300]
                    : theme.palette.groovy.onDark[700]
                }
                color={theme.palette.text.primary}
                isTestUser={false}
              />
            ) : (
              <>
                <ProfileAvatar size={24} userKey={users[0]} />
                {isOnline && <div className={classes.presence} />}
              </>
            )}
          </div>
        }
        name={chatChannelName}
        nameNode={
          <DiscoText
            variant={isUnread ? "body-sm-700" : "body-sm-500"}
            truncateText={1}
            title={chatChannelName}
          >
            {chatChannelName}
          </DiscoText>
        }
      />
    )
  }

  return (
    <DiscoContainerButton
      className={classNames(classes.container, { [classes.selected]: isSelected })}
      onClick={() => {
        history.push(
          generatePath(ROUTE_NAMES.COMMUNITY.DIRECT_MESSAGES.DETAIL, {
            channelId: chatChannel.id,
          })
        )
      }}
      testid={`${testid}.page-${chatChannelName}`}
    >
      {/* FUTURE: support non-DM channels */}
      <div className={classes.relativeParent}>
        <ChatChannelAvatarStack
          users={users}
          channelId={chatChannel.id}
          countVariant={users.length > 1 ? "placeholder" : undefined}
          avatarSize={40}
        />
        {isOnline && <div className={classes.presence} />}
      </div>
      <div className={classes.nameAndMessageContainer}>
        {/* Channel name */}
        <DiscoText variant={"body-sm-500"}>{chatChannelName}</DiscoText>
        {/* Latest message preview */}
        {renderMessagePreview()}
      </div>
      {/* Date and notification badge */}
      <div className={classes.date}>
        {renderDate()}
        <NotificationBadge config={{ channels: [streamChannel] }} />
      </div>
    </DiscoContainerButton>
  )

  function renderDate() {
    const formatted = formatStreamMessageDate(lastMessage?.created_at)
    if (!formatted) return null
    return (
      <DiscoTooltip content={formatted.tooltip}>
        <DiscoText variant={"body-xs-500"} color={"groovy.grey.500"}>
          {formatted.display}
        </DiscoText>
      </DiscoTooltip>
    )
  }

  function renderMessagePreview() {
    let textVariant: TextVariantWithModifiers
    if (isUnread) {
      textVariant = "body-sm-500"
    } else {
      textVariant = "body-sm"
    }
    if (!streamChannel) {
      return <DiscoTextSkeleton variant={textVariant} width={"75%"} />
    }

    if (!lastMessage) return null

    const name =
      lastMessage.user_id === stream?.user?.id ? "You" : lastMessage.user?.first_name

    const content =
      lastMessage.text || (lastMessage.attachments?.length ? "Sent attachment" : "")

    return (
      <DiscoText
        truncateText={1}
        variant={textVariant}
        className={classes.previewMessageText}
      >{`${name}: ${content}`}</DiscoText>
    )
  }

  function checkMembersOnline() {
    if (!streamChannel) return false
    for (const member in streamChannel.state.members) {
      if (member) {
        const { user_id, user } = streamChannel.state.members[member]
        if (user_id !== stream.user?.id && user?.online) return true
      }
    }
    return false
  }
}

type StyleProps = {
  variant: "sidebar" | "page"
  isUnread: boolean
  isSelected?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ isSelected }: StyleProps) => ({
    display: "flex",
    position: "relative",
    textAlign: "left",
    alignItems: "center",
    backgroundColor: isSelected
      ? theme.palette.primary.main
      : theme.palette.background.paper,
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1, 1.5),
    margin: theme.spacing(1.5, 1.5, 3),
    gap: theme.spacing(1),
    "&:hover": {
      backgroundColor: isSelected
        ? theme.palette.primary.main
        : theme.palette.groovy.neutral[100],
    },
    "&::before": {
      content: "''",
      position: "absolute",
      width: `calc(100% + ${theme.spacing(3)}px)`,
      height: 1,
      top: -12,
      left: theme.spacing(-1.5),
      zIndex: theme.zIndex.raise1,
      backgroundColor: theme.palette.constants.divider,
    },
  }),
  previewMessageText: ({ isUnread }: StyleProps) => ({
    color:
      theme.palette.type === "dark"
        ? theme.palette.groovy.onDark[200]
        : theme.palette.groovy.neutral[500],
    ...styleIf(isUnread, {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[100]
          : theme.palette.groovy.neutral[700],
    }),
  }),
  presence: ({ variant }: StyleProps) => ({
    position: "absolute",
    bottom: 0,
    right: 0,
    height: variant === "page" ? "12px" : "8px",
    width: variant === "page" ? "12px" : "8px",
    border: `2px solid ${theme.palette.common.white}`,
    borderRadius: theme.measure.borderRadius.big,
    backgroundColor: theme.palette.groovy.green[400],
    zIndex: theme.zIndex.raise1,
  }),
  relativeParent: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  nameAndMessageContainer: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    flexGrow: 1,
  },
  date: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: theme.spacing(0.5),
    "& > span": {
      whiteSpace: "nowrap",
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.onDark[200]
          : theme.palette.groovy.neutral[400],
    },
  },
  selected: {
    "& *": {
      color: `${theme.palette.primary.contrastText} !important`,
    },
  },
}))

export function DirectMessagesListItemSkeleton({ variant }: Pick<Props, "variant">) {
  const classes = useStyles({ variant, isUnread: false })
  if (variant === "sidebar") return <DiscoSideBarItemSkeleton />
  return (
    <div className={classes.container}>
      <Skeleton
        variant={"circle"}
        width={"40px"}
        height={"40px"}
        style={{ flexShrink: 0 }}
      />
      <div style={{ width: "100%" }}>
        <DiscoTextSkeleton variant={"body-md-600"} width={"50%"} />
        <DiscoTextSkeleton variant={"body-md-600"} width={"100%"} />
      </div>
    </div>
  )
}

export default DirectMessagesListItem
