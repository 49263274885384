/**
 * @generated SignedSource<<3c097ba4cbd95f23f29e875609d2ab0d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ChatChannelBotSuggestedMessageFragment$data = {
  readonly id: string;
  readonly suggestedMessage: string | null;
  readonly " $fragmentType": "ChatChannelBotSuggestedMessageFragment";
};
export type ChatChannelBotSuggestedMessageFragment$key = {
  readonly " $data"?: ChatChannelBotSuggestedMessageFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ChatChannelBotSuggestedMessageFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChatChannelBotSuggestedMessageFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "suggestedMessage",
      "storageKey": null
    }
  ],
  "type": "BotQueuedMessage",
  "abstractKey": null
};

(node as any).hash = "7a7aade140b9e091bc57de27b6fc1706";

export default node;
