/**
 * @generated SignedSource<<1421eadf3fd9095a8085a441b9c9c2db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
export type ChatChannelKind = "chat_bot" | "custom" | "default" | "direct_message" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type DirectMessagesListItemFragment$data = {
  readonly app: {
    readonly customAppTitle: string | null;
  } | null;
  readonly chatChannelMembers: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly user: {
          readonly firstName: string | null;
          readonly fullName: string;
          readonly id: string;
          readonly lastName: string | null;
          readonly " $fragmentSpreads": FragmentRefs<"ProfileAvatarFragment" | "ProfileListModalListItemFragment">;
        };
      };
    }>;
    readonly totalCount: number;
  };
  readonly externalChannelId: string;
  readonly id: string;
  readonly kind: ChatChannelKind;
  readonly " $fragmentType": "DirectMessagesListItemFragment";
};
export type DirectMessagesListItemFragment$key = {
  readonly " $data"?: DirectMessagesListItemFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"DirectMessagesListItemFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DirectMessagesListItemFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "externalChannelId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ProductApp",
      "kind": "LinkedField",
      "name": "app",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "customAppTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "excludeViewer",
          "value": true
        }
      ],
      "concreteType": "ChatChannelMemberNodeConnection",
      "kind": "LinkedField",
      "name": "chatChannelMembers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ChatChannelMemberNodeEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ChatChannelMember",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "fullName",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileAvatarFragment"
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ProfileListModalListItemFragment"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "chatChannelMembers(excludeViewer:true)"
    }
  ],
  "type": "ChatChannel",
  "abstractKey": null
};
})();

(node as any).hash = "b211d319419987e25bc6929dbdab72a7";

export default node;
