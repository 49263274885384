import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelCustomAttachment from "@components/chat/channel/attachment/ChatChannelCustomAttachment"
import { DiscoText } from "@disco-ui"
import classNames from "classnames"
import { useMessageContext } from "stream-chat-react"

const QuotedMessage = () => {
  const { message } = useMessageContext()
  const classes = useStyles()

  const { quoted_message } = message
  if (!quoted_message) return null

  const quotedMessageAttachment = quoted_message.attachments?.length
    ? quoted_message.attachments![0]
    : null

  if (!quoted_message.text && !quotedMessageAttachment) return null

  return (
    <>
      <div className={classNames(classes.quotedMessage, "quoted-message")}>
        <div className={classes.quotedMessageWrapper}>
          {quotedMessageAttachment && (
            <ChatChannelCustomAttachment attachments={[quotedMessageAttachment]} />
          )}
          <DiscoText variant={"body-sm"} color={"groovy.onDark.300"}>
            {quoted_message.text}
          </DiscoText>
        </div>
      </div>
      {message.attachments?.length && message.quoted_message ? (
        <ChatChannelCustomAttachment attachments={message.attachments} />
      ) : null}
    </>
  )
}

const useStyles = makeUseStyles((theme) => ({
  quotedMessage: {
    display: "flex",
    alignItems: "flex-start",
  },
  quotedMessageWrapper: {
    borderLeft: `2px solid ${theme.palette.groovy.neutral[300]}`,
    paddingLeft: theme.spacing(1),
  },
}))

export default QuotedMessage
