import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { useRef } from "react"
import { Attachment, AttachmentProps, Gallery } from "stream-chat-react"

export const ChatChannelCustomAttachment = (props: AttachmentProps) => {
  const classes = useStyles()
  const imageRefs = useRef<(HTMLElement | null)[]>([])

  if (props.attachments && props.attachments.some((att) => att.type === "image")) {
    const images = props.attachments
      .filter((att) => att.type === "image")
      .map((att) => ({
        image_url: att.image_url,
        thumb_url: att.thumb_url,
        previewUrl: att.image_url,
      }))

    if (images.length > 1) {
      return (
        <div className={classes.galleryContainer}>
          <Gallery images={images} innerRefs={imageRefs} />
        </div>
      )
    } else if (images.length === 1) {
      // Handle single image with custom styling
      return (
        <div className={`${classes.singleImageContainer} ${classes.modalImage}`}>
          <div className={classes.singleImageWrapper}>
            <Attachment {...props} />
          </div>
        </div>
      )
    }
  }

  return <Attachment {...props} />
}

const useStyles = makeUseStyles((theme) => ({
  galleryContainer: {
    width: "100%",
    overflowX: "auto",
    overflowY: "hidden",
    WebkitOverflowScrolling: "touch",
    scrollbarWidth: "none",
    msOverflowStyle: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& .str-chat__gallery": {
      display: "flex !important",
      flexDirection: "row !important",
      flexWrap: "nowrap !important",
      gap: theme.spacing(1),
      padding: 0,
      margin: 0,
      width: "100%",
      "& > *": {
        margin: "0 !important",
        padding: "0 !important",
      },
    },
    "& .str-chat__gallery--square": {
      gridTemplateColumns: "none !important",
    },
    "& .str-chat__gallery-two-rows": {
      gridTemplateRows: "none !important",
    },
    "& .str-chat__gallery-image, & .str-chat__gallery-placeholder": {
      width: "150px !important",
      height: "150px !important",
      margin: "0 !important",
      padding: "0 !important",
      borderRadius: theme.measure.borderRadius.medium,
      cursor: "pointer",
      border: "none",
      flexShrink: 0,
      "&:hover": {
        opacity: 0.9,
      },
    },
    "& .str-chat__gallery-placeholder": {
      position: "relative",
      backgroundSize: "cover !important",
      backgroundPosition: "center !important",
      "&::before": {
        content: '""',
        position: "absolute",
        inset: 0,
        backgroundColor: "rgba(0, 0, 0, 0.4)",
        borderRadius: theme.measure.borderRadius.medium,
      },
      "& p": {
        position: "relative",
        color: "white",
        margin: 0,
        fontSize: theme.typography.body1.fontSize,
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    "& .str-chat__base-image": {
      width: "100% !important",
      height: "100% !important",
      objectFit: "cover",
      borderRadius: theme.measure.borderRadius.medium,
    },
  },
  singleImageContainer: {
    display: "block",
    width: "100%",
    height: "auto",
    overflow: "hidden",
    textAlign: "left",
  },
  singleImageWrapper: {
    display: "inline-block",
    "& img": {
      width: "auto !important",
      height: "auto !important",
      maxWidth: "300px",
      maxHeight: "100%",
      borderRadius: theme.measure.borderRadius.large,
    },
  },
  modalImage: {
    "& .str-chat__modal__inner .image-gallery": {
      maxWidth: "100%",
      maxHeight: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: theme.measure.borderRadius.large,
    },
    "& .str-chat__modal__inner .image-gallery-slide": {
      display: "flex !important",
      justifyContent: "center",
      alignItems: "center",
      maxWidth: "100%",
      maxHeight: "100%",
      borderRadius: theme.measure.borderRadius.large,
      background: "none",
    },
    "& .str-chat__modal__inner .image-gallery-image": {
      maxWidth: "100% !important",
      maxHeight: "100% !important",
      width: "auto !important",
      height: "auto !important",
      objectFit: "contain !important",
    },
    "& .str-chat__modal": {
      backgroundColor: "rgba(0, 0, 0, 0.75) !important",
    },
  },
}))

export default ChatChannelCustomAttachment
