import VideoPlayIcon from "@/core/ui/iconsax/linear/videoPlayIcon.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { CustomChatChannelFileAttachment } from "@components/chat/channel/attachment/CustomChatChannelFileAttachment"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import classNames from "classnames"
import { LoadingIndicator } from "stream-chat-react"

export type ChatChannelFilePreviewerProps = {
  uploads?: {
    id?: string
    localMetadata?: {
      id: string
      file?: File
    }
    type?: string
    asset_url?: string
    title?: string
    state?: string
    file_size?: number
  }[]
  handleRemove?: (id: string) => void
  handleRetry?: (id: string) => void
  handleFiles?: (files: FileList) => void
}

/** Display a list of uploaded files */
export const ChatChannelFilePreviewer = ({
  uploads,
  handleRemove,
}: Partial<ChatChannelFilePreviewerProps>) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  return (
    <div className={classes.container}>
      <div className={classNames(showOnHoverClasses.hoverable, classes.list)}>
        {uploads?.map((upload) => {
          const id = upload.localMetadata?.id || upload.id

          if (!id) return null

          return (
            <div key={id} className={classes.file}>
              {upload.type === "video" ? (
                <>
                  <video src={upload.asset_url} className={classes.thumbnail} />
                  <VideoPlayIcon className={classes.playIcon} />
                </>
              ) : (
                <div className={classes.item}>
                  <CustomChatChannelFileAttachment
                    name={upload.title}
                    url={upload.asset_url}
                    size={upload.file_size}
                  />
                </div>
              )}

              {upload.state === "uploading" && (
                <div className={classes.loadingIndicator}>
                  <LoadingIndicator />
                </div>
              )}

              {handleRemove && (
                <ChatChannelRemoveAttachmentButton id={id} handleRemove={handleRemove} />
              )}
            </div>
          )
        })}
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    border: "none",
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  list: {
    display: "flex",
    height: "100%",
    gap: theme.spacing(2),
  },
  item: {
    height: "80px",
    width: "400px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.big,
    flexShrink: 0,
    position: "relative",
    border: "1px solid",
    boxShadow: theme.palette.groovyDepths.xs,
    borderColor: theme.palette.divider,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2),
  },
  thumbnail: {
    borderRadius: "12px",
    height: "80px",
    width: "80px",
    objectFit: "cover",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
  },
  file: {
    display: "flex",
    position: "relative",
  },
  playIcon: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
  loadingIndicator: {
    position: "absolute",
    right: theme.spacing(4),
  },
}))
