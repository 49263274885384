import SmileyFaceIcon from "@/core/ui/iconsax/linear/custom-face-content.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import { EmojiObject } from "@components/chat/channel/emoji-button/ChatEmoji"
import { DiscoTooltip } from "@disco-ui"
import DiscoEmojiPicker from "@disco-ui/emoji-picker/DiscoEmojiPicker"
import { IconButton, Popover, darken, lighten } from "@material-ui/core"
import classNames from "classnames"
import React, { Suspense, useRef, useState } from "react"
type Props = {
  onEmojiSelect: (emoji: string) => void
  useNativeEmoji?: boolean
  classes?: string
}

const ChatChannelEmojiPickerDropdown: React.FC<Props> = ({
  onEmojiSelect,
  useNativeEmoji = false,
  classes: propClasses,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const open = Boolean(anchorEl)

  // Track if we've ever opened the dropdown
  const hasOpened = useRef(false)
  hasOpened.current = hasOpened.current || open

  return (
    <>
      <DiscoTooltip content={"Add an Emoji"}>
        <IconButton
          onClick={handleClick}
          className={classNames(classes.iconButton, propClasses)}
          size={"small"}
        >
          <SmileyFaceIcon width={20} height={20} />
        </IconButton>
      </DiscoTooltip>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        keepMounted={Boolean(hasOpened.current)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        PaperProps={{
          style: {
            width: "350px",
          },
        }}
        marginThreshold={16}
        classes={{ paper: classes.paper }}
      >
        <Suspense fallback={<div className={classes.placeholder} />}>
          {open ? (
            <DiscoEmojiPicker
              skinTonesDisabled
              onChange={(e) => {
                handleSelect(e)
              }}
              onClose={handleClose}
            />
          ) : (
            <div className={classes.placeholder} />
          )}
        </Suspense>
      </Popover>
    </>
  )

  function handleSelect(emoji: EmojiObject) {
    onEmojiSelect(useNativeEmoji ? emoji.native : emoji.id)
    handleClose()
  }

  function handleClick(event: React.MouseEvent<HTMLButtonElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleClose() {
    setAnchorEl(null)
  }
}
const useStyles = makeUseStyles((theme) => ({
  paper: {
    maxWidth: "none",
    padding: 0,
    "& .emoji-mart-scroll": {
      height: "360px",
    },
    "& .emoji-mart": {
      // for background of emoji picker
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
    },
    "& .emoji-mart-bar": {
      borderColor: theme.palette.divider,
    },
    "& .emoji-mart-category-label span": {
      // for intermediary category labels: eg: Frequently used, Smileys & People, etc
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.secondary,
    },
    "& .emoji-mart-anchor-selected": {
      "& .emoji-mart-anchor-bar": {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      "& .emoji-mart-anchor-icon svg": {
        fill: theme.palette.primary.main,
      },
    },
    "& .emoji-mart-search input": {
      backgroundColor: theme.palette.background.paper,
      borderColor: theme.palette.divider,
      color: theme.palette.text.primary,
    },
    "& .emoji-mart-search-icon svg": {
      fill: `${theme.palette.text.primary} !important`,
    },
    "& .emoji-mart-category .emoji-mart-emoji:hover::before": {
      backgroundColor:
        theme.palette.type === "light"
          ? lighten(theme.palette.primary.light, 0.5)
          : darken(theme.palette.primary.dark, 0.8),
    },
    "& .emoji-mart-skin-swatches": {
      backgroundColor: theme.palette.background.paper, // Update skin tone picker background color
    },
    "& .emoji-mart-preview-data": {
      color: theme.palette.text.secondary,
    },
  },
  placeholder: {
    width: "350px",
    height: "420px",
  },
  iconButton: {
    width: "30px",
    height: "30px",
    borderRadius: "50%",
  },
}))

export default React.memo(ChatChannelEmojiPickerDropdown)
