import { StreamChatUserData } from "@/core/context/StreamChatContext"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelAvatar from "@components/chat/channel/detail/avatar/ChatChannelAvatar"
import ChatChannelEditMessageInput from "@components/chat/channel/edit/ChatChannelEditMessageInput"
import { ChatChannelEditMessageFragment$key } from "@components/chat/channel/edit/__generated__/ChatChannelEditMessageFragment.graphql"
import ChatChannelMessageOptions from "@components/chat/channel/message-options/ChatChannelMessageOptions"
import { DiscoText } from "@disco-ui"
import classNames from "classnames"
import React, { useState } from "react"
import { graphql, useFragment } from "react-relay"
import {
  MessageDeleted as DefaultMessageDeleted,
  MessageTimestamp as DefaultMessageTimestamp,
  MessageContextValue,
  MessageUIComponentProps,
  useComponentContext,
  useMessageContext,
} from "stream-chat-react"

interface Props extends MessageContextValue {
  chatChannelKey: ChatChannelEditMessageFragment$key
}

const MessageWithContext = (props: Props) => {
  const { editing, handleRetry, message, chatChannelKey } = props

  const {
    MessageDeleted = DefaultMessageDeleted,
    MessageTimestamp = DefaultMessageTimestamp,
  } = useComponentContext()

  const chatChannel = useFragment<ChatChannelEditMessageFragment$key>(
    graphql`
      fragment ChatChannelEditMessageFragment on ChatChannel {
        id
        ...ChatChannelMessageOptionsFragment
      }
    `,
    chatChannelKey
  )

  const isFailedMessage = message.status === "failed"

  const [showOptions, setShowOptions] = useState(false)
  const classes = useStyles({ isFailedMessage })

  if (message.deleted_at || message.type === "deleted") {
    return <MessageDeleted message={message} />
  }

  return (
    <div className={classes.container}>
      <div
        key={message.id}
        className={classes.text}
        data-testid={"ChatChannelSimpleMessage.container"}
        onMouseEnter={handleShowOptions}
        onMouseLeave={handleHideOptions}
        onClick={handleRetryMessage}
        onKeyDown={handleRetryMessage}
        role={"button"}
        tabIndex={0}
      >
        {message.user && <ChatChannelAvatar user={message.user as StreamChatUserData} />}
        <div data-testid={"message-inner"} className={classes.message}>
          {/* User */}
          <div className={classNames(classes.nameAndDate, "message__name-and-date")}>
            {message.user?.name ? (
              <DiscoText variant={"body-md-600"} display={"inline"} marginRight={1}>
                {message.user.name}
              </DiscoText>
            ) : null}

            <MessageTimestamp calendar customClass={classes.timestamp} />
          </div>

          {/* Options */}
          {showOptions && <ChatChannelMessageOptions chatChannelKey={chatChannel} />}

          {editing && <ChatChannelEditMessageInput />}
        </div>
      </div>
    </div>
  )

  function handleRetryMessage() {
    if (isFailedMessage) {
      handleRetry(message)
    }
  }

  function handleShowOptions() {
    setShowOptions(true)
  }
  function handleHideOptions() {
    setShowOptions(false)
  }
}

type StyleProps = {
  isFailedMessage: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
  },
  text: {
    display: "flex",
    padding: theme.spacing(1.5),
    backgroundColor: theme.palette.groovy.neutral[100],

    borderRadius: theme.measure.borderRadius.big,

    cursor: ({ isFailedMessage }: StyleProps) =>
      isFailedMessage ? "pointer" : "default",

    "& .str-chat__avatar": {
      width: "40px",
      height: "40px",
      flex: "0 0 40px",
      marginRight: theme.spacing(2),
    },
    "& .str-chat__message-mention": {
      color: theme.palette.primary.main,
      fontSize: "15px",
    },
    "& .str-chat__message-text-inner": {
      color: theme.palette.groovy.grey[700],
      padding: 0,
      borderRadius: 0,
      border: "none",
      backgroundColor: "transparent",
      minHeight: "auto",

      "& p": {
        ...theme.typography["body-sm"],
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "none",
      },
    },
    "& .str-chat__message-attachment--img": {
      maxWidth: "300px",
      borderRadius: "16px",
    },
    "& .str-chat__gallery": {
      justifyContent: "flex-start",
    },
    "& .str-chat__gallery-image img": {
      borderRadius: "16px",
    },
    "& .str-chat__message-url-link": {
      whiteSpace: "unset",
      wordBreak: "break-all",
    },
    "& .str-chat__message-attachment--card--no-image": {
      height: "unset",
    },
    "& .str-chat__message-attachment-card": {
      marginTop: 0,
      width: "fit-content",
      "&--url": {
        textTransform: "unset",
      },
      "&--header": {
        height: "unset",
        width: "unset",
        maxHeight: "175px",
        "& img": {
          width: "auto",
          height: "auto",
          margin: "auto",
        },
      },
      [theme.breakpoints.up("sm")]: {
        "&--image": {
          display: "flex",
          alignItems: "center",
        },
      },
    },
  },
  nameAndDate: {
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
  },
  timestamp: {
    ...theme.typography["body-sm"],
    fontSize: "12px",
    color: theme.palette.groovy.grey[400],
  },
  message: {
    width: "100%",
    minWidth: "0px",
    maxWidth: "100%",
  },
}))

const MemoizedMessage = React.memo(MessageWithContext) as typeof MessageWithContext

/**
 * The default UI component that renders a message and receives functionality and logic from the MessageContext.
 */
const ChatChannelEditMessage = (
  props: MessageUIComponentProps & { chatChannelKey: Props["chatChannelKey"] }
) => {
  const messageContext = useMessageContext()

  return <MemoizedMessage {...messageContext} {...props} />
}

export default ChatChannelEditMessage
