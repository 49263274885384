import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import DiscoImage from "@disco-ui/image/DiscoImage"
import classNames from "classnames"
import { ReactNode } from "react"

interface AvatarProps {
  alt: string
  src: string | undefined | null
  placeholder?: ReactNode
  className?: string
  size?: number
  /** If true, apply a drop shadow on the component */
  dropShadow?: boolean
  shape?: AvatarShape
  isTestUser: boolean
}

export type AvatarShape = "circle" | "square"

function Avatar({
  alt,
  src,
  className,
  placeholder,
  size,
  dropShadow = false,
  shape = "circle",
  isTestUser,
}: AvatarProps) {
  const defaultAvatarSize = 24
  const classes = useStyles({ size: size || defaultAvatarSize, shape, isTestUser })

  return src ? (
    <div
      className={classNames(classes.container, "avatar", className, {
        [classes.dropShadow]: dropShadow,
      })}
    >
      <DiscoImage alt={alt} src={src!} className={classes.avatar} />
    </div>
  ) : (
    <>{placeholder}</>
  )
}

type StyleProps = {
  size: number
  shape: AvatarShape
  isTestUser: boolean
}

const useStyles = makeUseStyles((theme) => ({
  container: ({ size, shape, isTestUser }: StyleProps) => ({
    display: "block",
    aspectRatio: 1,
    width: size,
    height: size,
    borderRadius: shape === "circle" ? "50%" : "4px",
    border: `${getAvatarBorderWidth(size)}px solid ${
      isTestUser
        ? theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black
        : theme.palette.common.white
    }`,
  }),
  avatar: ({ size, shape, isTestUser }: StyleProps) => ({
    width: "100%",
    height: "100%",
    borderRadius: shape === "circle" ? "50%" : "4px",
    border: isTestUser
      ? `${getAvatarBorderWidth(size)}px solid ${
          theme.palette.type === "dark"
            ? theme.palette.common.black
            : theme.palette.common.white
        }`
      : undefined, // Use double borders because outline makes the avatar look bigger since it ignores box sizing
  }),
  dropShadow: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export function getAvatarBorderWidth(size: number) {
  if (size < 32) {
    return 1
  } else if (size < 64) {
    return 1.5
  }
  return 2
}

export default Avatar
