import { useDrawerContext } from "@/core/context/DrawerContext"
import MessageIcon from "@/core/ui/iconsax/linear/message-text.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelEmojiButton from "@components/chat/channel/emoji-button/ChatChannelEmojiButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import ChatChannelMessageActions from "@components/chat/channel/message-actions/ChatChannelMessageActions"
import { ChatChannelMessageOptionsFragment$key } from "@components/chat/channel/message-options/__generated__/ChatChannelMessageOptionsFragment.graphql"
import { defaultReactions } from "@components/chat/channel/util/chatChannelConstants"
import { DiscoIcon, DiscoIconButton, DiscoTooltip } from "@disco-ui"
import usePermissions from "@utils/hook/usePermissions"
import classNames from "classnames"
import { useCallback } from "react"
import { useFragment } from "react-relay"
import { graphql } from "relay-runtime"
import { showMessageActionsBox, useMessageContext } from "stream-chat-react"
interface Props {
  className?: string
  chatChannelKey: ChatChannelMessageOptionsFragment$key
}

function ChatChannelMessageOptions(props: Props) {
  const { className, chatChannelKey } = props

  const {
    customMessageActions,
    getMessageActions,
    handleOpenThread,
    initialMessage: threadHeader,
    handleReaction,
    message,
    threadList: isInThread,
    handlePin,
  } = useMessageContext()

  const chatChannel = useFragment<ChatChannelMessageOptionsFragment$key>(
    graphql`
      fragment ChatChannelMessageOptionsFragment on ChatChannel {
        id
        ...usePermissionsFragment
        ...ChatChannelMessageActionsFragment
      }
    `,
    chatChannelKey
  )

  const classes = useStyles({ threadHeader })
  const messageActions = getMessageActions()
  const showActionsBox = showMessageActionsBox(messageActions) || !!customMessageActions
  const permissions = usePermissions(chatChannel)
  const { closeDrawer } = useDrawerContext()

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      handleReaction(emoji, undefined as any)
    },
    [handleReaction]
  )

  if (
    !message.type ||
    message.type === "error" ||
    message.type === "system" ||
    message.type === "ephemeral" ||
    message.status === "failed" ||
    message.status === "sending"
  ) {
    return null
  }

  return (
    <div
      className={classNames(classes.optionsContainer, className)}
      data-testid={"message-options"}
    >
      {/* Default Reactions */}
      {defaultReactions.map((emoji) => (
        <ChatChannelEmojiButton key={emoji.id} emoji={emoji} />
      ))}

      {/* Emoji Picker */}
      <ChatChannelEmojiPickerDropdown
        onEmojiSelect={handleEmojiClick}
        classes={classes.iconButton}
      />

      {/* Pin */}
      {messageActions.includes("pin") && permissions.has("chat.manage") && (
        <DiscoTooltip content={message.pinned ? "Unpin Message" : "Pin Message"}>
          <DiscoIconButton
            onClick={handlePin}
            className={classes.iconButton}
            size={"small"}
          >
            <DiscoIcon icon={"pin"} active={message.pinned} />
          </DiscoIconButton>
        </DiscoTooltip>
      )}

      {/* Thread */}
      {message.type !== "reply" && !isInThread && (
        <DiscoTooltip content={"Reply in thread"}>
          <DiscoIconButton
            testid={"message-options.reply-in-thread"}
            onClick={(e) => handleReplyInThread(e)}
            className={classes.iconButton}
            size={"small"}
          >
            <MessageIcon width={"24px"} height={"24px"} />
          </DiscoIconButton>
        </DiscoTooltip>
      )}

      {/* More Actions */}
      {showActionsBox && (
        <DiscoTooltip content={"More actions"}>
          <div>
            <ChatChannelMessageActions chatChannelKey={chatChannel} />
          </div>
        </DiscoTooltip>
      )}
    </div>
  )

  function handleReplyInThread(event: React.MouseEvent<Element, MouseEvent>) {
    if (closeDrawer) closeDrawer()
    handleOpenThread(event)
  }
}

type StyleProps = {
  threadHeader?: boolean
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? `${theme.palette.groovy.grey[100]} !important`
          : `${theme.palette.groovy.grey[400]} !important`,
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  optionsContainer: ({ threadHeader }: StyleProps) => ({
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.boxShadow,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: theme.measure.borderRadius.medium,
    position: "absolute",
    top: threadHeader ? "15px" : "-5px",
    right: threadHeader ? "16px" : "10px",
    zIndex: 2,
    padding: theme.spacing(1, 0.5),
    height: "40px",
  }),
}))

export default ChatChannelMessageOptions
