import {
  EmoticonItem,
  type AutocompleteMinimalData,
  type EmojiSearchIndex,
  type EmojiSearchIndexResult,
  type EmojiTriggerSetting,
} from "stream-chat-react"

export const useEmojiTrigger = (
  emojiSearchIndex?: EmojiSearchIndex
): EmojiTriggerSetting => ({
  component: EmoticonItem,
  dataProvider: (query, text, onReady) => {
    if (query.length === 0 || query.charAt(0).match(/[^a-zA-Z0-9+-]/)) {
      return []
    }

    if (!emojiSearchIndex) return []

    const searchResult = emojiSearchIndex.search(query)
    if (!searchResult) return []

    Promise.resolve(searchResult).then((emojis) => {
      if (!emojis) return

      const result = emojis
        .filter((emoji): emoji is EmojiSearchIndexResult => Boolean(emoji))
        .slice(0, 10)

      if (onReady) onReady(result as AutocompleteMinimalData[], query)
    })

    return []
  },
  output: (entity) => {
    const emojiEntity = entity as EmojiSearchIndexResult
    return {
      caretPosition: "next",
      key: emojiEntity.id,
      text: emojiEntity.native ?? "",
    }
  },
})
