import { StreamChatUserData } from "@/core/context/StreamChatContext"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"
import { DiscoText } from "@disco-ui"
import { IconButton, useTheme } from "@material-ui/core"
import {
  Attachment,
  QuotedMessagePreviewProps,
  useChannelActionContext,
} from "stream-chat-react"

const QuotedMessagePreviewHeader = () => {
  const { setQuotedMessage } = useChannelActionContext()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.header}>
      <DiscoText variant={"body-sm"} color={"text.secondary"}>
        {"Reply to message:"}
      </DiscoText>
      <IconButton size={"small"} onClick={() => setQuotedMessage(undefined)}>
        <CloseIcon width={24} height={24} color={theme.palette.text.secondary} />
      </IconButton>
    </div>
  )
}

const ChatChannelQuotedMessagePreview: React.FC<QuotedMessagePreviewProps> = (props) => {
  const { quotedMessage } = props
  const classes = useStyles()

  const quotedMessageAttachment = quotedMessage.attachments?.length
    ? quotedMessage.attachments[0]
    : null

  if (!quotedMessage.text && !quotedMessageAttachment) return null

  const user = quotedMessage.user as StreamChatUserData

  return (
    <div className={classes.quotedContainer}>
      <QuotedMessagePreviewHeader />
      <div className={classes.content}>
        <UserAvatar
          className={classes.avatar}
          user={{
            id: Relay.toGlobalId("User", user.disco_user_id),
            first_name: user.first_name,
            last_name: user.last_name,
            avatar: user.avatar,
            name: user.name,
            is_test_user: user.is_disco_test_user || false,
          }}
          testid={"avatar"}
        />

        <div>
          {quotedMessage.user && (
            <DiscoText variant={"body-md-600"} marginBottom={0.5}>
              {quotedMessage.user.name}
            </DiscoText>
          )}

          {quotedMessageAttachment && (
            <Attachment attachments={[quotedMessageAttachment]} />
          )}
          <DiscoText variant={"body-sm"} color={"text.secondary"}>
            {quotedMessage.text}
          </DiscoText>
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingBottom: theme.spacing(1),
  },
  quotedContainer: {
    paddingBottom: theme.spacing(3),

    "& .str-chat__message-attachment--image": {
      maxWidth: "300px",
      borderRadius: "16px",
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    gap: theme.spacing(2),
    background: theme.palette.groovy.neutral[100],
    borderRadius: theme.measure.borderRadius.big,
    padding: theme.spacing(1.5),
  },
  avatar: {
    alignSelf: "flex-start",
    width: "40px !important",
    height: "40px !important",
  },
}))

export default ChatChannelQuotedMessagePreview
