import Relay from "@/relay/relayUtils"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import useShowOnHoverStyles from "@assets/style/util/useShowOnHoverStyles"
import { ChatChannelRemoveAttachmentButton } from "@components/chat/channel/uploads/ChatChannelRemoveAttachmentButton"
import {
  ATTACH_BLOCK_CONFIG,
  AttachBlockEntity,
} from "@components/editor/plugins/attach-block/AttachBlockNode"
import DiscoContainerButton from "@disco-ui/button/DiscoContainerButton"
import classNames from "classnames"
import React from "react"

export declare type Props = {
  type: AttachBlockEntity
  entityId: string
  handleRemove?: (id: string) => void
}

const ChatChannelAttachBlock = ({ type, entityId, handleRemove }: Props) => {
  const classes = useStyles()
  const showOnHoverClasses = useShowOnHoverStyles()

  const config = ATTACH_BLOCK_CONFIG[type]
  const component = React.createElement(config.Component, {
    entityId,
  })

  return (
    <div className={classes.container}>
      <div className={classNames(showOnHoverClasses.hoverable, classes.list)}>
        <div key={entityId} className={classes.item}>
          <DiscoContainerButton className={classes.containerButton} disabled>
            {component}
          </DiscoContainerButton>
          {handleRemove && (
            <ChatChannelRemoveAttachmentButton
              id={entityId}
              handleRemove={handleRemove}
            />
          )}
        </div>
      </div>
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    border: 0,
    display: "flex",
    flexDirection: "column",
    flexShrink: 0,
  },
  list: {
    display: "flex",
  },
  item: {
    height: "80px",
    display: "flex",
    alignItems: "center",
    borderRadius: theme.measure.borderRadius.medium,
    flexShrink: 0,
    position: "relative",
    width: "400px",
  },

  containerButton: {
    backgroundColor: "transparent",
    opacity: 1,
    width: "100%",
    "& button": {
      opacity: 0.4,
    },
  },
}))

export default Relay.withSkeleton({
  component: ChatChannelAttachBlock,
  skeleton: () => null,
})
