import CheckIcon from "@/core/ui/iconsax/linear/custom-check.svg"
import CloseIcon from "@/core/ui/iconsax/linear/custom-x.svg"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { TestIDProps } from "@utils/typeUtils"
import classNames from "classnames"

interface AICheckProps extends TestIDProps {
  isChecked?: boolean
  hasError?: boolean
  size?: "small" | "medium"
}

function AICheck({
  hasError,
  isChecked,
  size = "medium",
  testid = "AICheck",
}: AICheckProps) {
  const classes = useStyles()
  if (hasError)
    return (
      <div
        data-testid={testid}
        className={classNames(classes.root, classes.error, classes[size])}
      >
        <CloseIcon className={classes.icon} />
      </div>
    )

  return (
    <div
      data-testid={testid}
      className={classNames(
        classes.root,
        classes[size],
        isChecked ? classes.checked : classes.unchecked
      )}
    >
      {isChecked ? <CheckIcon className={classes.icon} /> : null}
    </div>
  )
}

const useStyles = makeUseStyles((theme) => ({
  root: {
    borderRadius: "50%",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    flexShrink: 0,
  },
  medium: {
    minHeight: 24,
    minWidth: 24,
    height: 24,
    width: 24,
    padding: theme.spacing(0.25),
  },
  small: {
    minHeight: 18,
    minWidth: 18,
    height: 18,
    width: 18,
    padding: theme.spacing(0.25),
  },
  icon: {
    width: "100%",
    height: "100%",
    display: "block",
  },
  checked: {
    background:
      theme.palette.type === "dark"
        ? theme.palette.aiGradient.bluePurple02
        : theme.palette.aiGradient.bluePurple03,
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
  unchecked: {
    backgroundColor: theme.palette.background.paper,
    border: `1.5px solid ${theme.palette.divider}`,
  },
  error: {
    backgroundColor: theme.palette.error.main,
    color:
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white,
  },
}))

export default AICheck
