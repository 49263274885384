import ChatBotAvatar from "@/admin/integrations/chat-bot/ChatBotAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoInput, DiscoInputProps } from "@disco-ui"
import { TestIDProps } from "@utils/typeUtils"
import { observer } from "mobx-react-lite"
import { ForwardedRef } from "react"

type AIInputProps = Omit<
  DiscoInputProps,
  "classes" | "padding" | "autoFocus" | "multiline" | "startAdornment"
> &
  TestIDProps & {
    showBadge?: boolean
    onEnterPress?: (
      e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => void
  }

const AIInput = (
  { showBadge = true, testid, onEnterPress, ...rest }: AIInputProps,
  ref: ForwardedRef<HTMLTextAreaElement | HTMLInputElement>
) => {
  const classes = useStyles()

  const handleKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    // Handle tab key for placeholder insertion
    if (
      event.key === "Tab" &&
      !event.shiftKey &&
      !event.currentTarget.value &&
      rest.placeholder &&
      rest.onChange
    ) {
      event.preventDefault()
      rest.onChange({
        target: { value: rest.placeholder },
      } as React.ChangeEvent<HTMLInputElement>)
    }

    // Handle enter key press only if onEnterPress is provided
    if (
      event.key === "Enter" &&
      !event.nativeEvent.isComposing &&
      !event.shiftKey &&
      onEnterPress
    ) {
      event.preventDefault() // Only prevent default if we're handling the enter press
      onEnterPress(event)
    }
  }

  return (
    <DiscoInput
      ref={ref}
      minHeight={"24px"}
      {...rest}
      classes={{ root: classes.aiInput }}
      data-testid={testid}
      padding={"10px 12px"}
      multiline
      // eslint-disable-next-line jsx-a11y/no-autofocus
      autoFocus
      startAdornment={
        showBadge && (
          <div className={classes.space}>
            <ChatBotAvatar size={20} />
          </div>
        )
      }
      onKeyDown={handleKeyDown}
    />
  )
}

const useStyles = makeUseStyles((theme) => ({
  aiInput: {
    backgroundColor: theme.palette.background.paper,
    border: `solid 1.5px ${theme.palette.groovy.neutral[200]}`,
    "&:hover:not(.Mui-focused)": {
      borderColor: theme.palette.groovy.purple[theme.palette.type === "dark" ? 600 : 300],
      backgroundColor: theme.palette.background.paper,
    },
    "&.Mui-focused": {
      borderColor: "transparent",
      borderRadius: theme.measure.borderRadius.big,
      boxShadow: `0px 0px 0px 3px ${
        theme.palette.groovy.purple[theme.palette.type === "dark" ? 600 : 200]
      }`,
      backgroundImage: `linear-gradient(${theme.palette.background.paper}, ${theme.palette.background.paper}), ${theme.palette.aiGradient.aiDark}`,
      backgroundOrigin: "border-box",
      backgroundClip: "padding-box, border-box",
    },
  },
  space: {
    "& svg": {
      width: 20,
      height: 20,
    },
    padding: theme.spacing(0, 1, 0, 0),
    marginBottom: theme.spacing(-2),
  },
}))

export default observer(AIInput, { forwardRef: true })
