/**
 * @generated SignedSource<<2cc53d05002a51659b63d26b6889c540>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommunitySwitcherListQuery$variables = Record<PropertyKey, never>;
export type CommunitySwitcherListQuery$data = {
  readonly viewer: {
    readonly id: string;
    readonly organizationMemberships: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly id: string;
          readonly organizationId: string;
          readonly " $fragmentSpreads": FragmentRefs<"CommunitySwitcherListItemFragment">;
        };
      }>;
    };
  } | null;
};
export type CommunitySwitcherListQuery = {
  response: CommunitySwitcherListQuery$data;
  variables: CommunitySwitcherListQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "direction",
    "value": "DESC"
  },
  {
    "kind": "Literal",
    "name": "field",
    "value": "creation_datetime"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "CommunitySwitcherListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrganizationMembershipNodeConnection",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembershipNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "CommunitySwitcherListItemFragment"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationMemberships(direction:\"DESC\",field:\"creation_datetime\")"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "CommunitySwitcherListQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "OrganizationMembershipNodeConnection",
            "kind": "LinkedField",
            "name": "organizationMemberships",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "OrganizationMembershipNodeEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "OrganizationMembership",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v0/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "slug",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Badge",
                            "kind": "LinkedField",
                            "name": "badge",
                            "plural": false,
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "kind",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "icon",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "emoji",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "mediaUrl",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "primaryDomain",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "organizationMemberships(direction:\"DESC\",field:\"creation_datetime\")"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "72f38cc04ea8753751b9e913d02f4e8b",
    "id": null,
    "metadata": {},
    "name": "CommunitySwitcherListQuery",
    "operationKind": "query",
    "text": "query CommunitySwitcherListQuery {\n  viewer {\n    id\n    organizationMemberships(field: creation_datetime, direction: DESC) {\n      edges {\n        node {\n          id\n          organizationId\n          ...CommunitySwitcherListItemFragment\n        }\n      }\n    }\n  }\n}\n\nfragment BadgeFragment on Badge {\n  kind\n  color\n  icon\n  emoji\n  mediaUrl\n}\n\nfragment CommunityBadgeFragment on Organization {\n  name\n  badge {\n    id\n    kind\n    icon\n    color\n    ...BadgeFragment\n  }\n}\n\nfragment CommunitySwitcherListItemFragment on OrganizationMembership {\n  organization {\n    id\n    name\n    slug\n    badge {\n      id\n      kind\n      icon\n      color\n      ...BadgeFragment\n    }\n    primaryDomain\n    ...CommunityBadgeFragment\n  }\n}\n"
  }
};
})();

(node as any).hash = "db84d14e0b0e91ff0fe33e73cd7b7a30";

export default node;
