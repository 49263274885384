import { useLogout } from "@/authentication/logout/util/useLogout"
import { useActiveOrganization } from "@/core/context/ActiveOrganizationContext"
import { useActiveProduct } from "@/core/context/ActiveProductContext"
import { useAuthUser } from "@/core/context/AuthUserContext"
import { useGlobalDrawer } from "@/core/context/GlobalDrawerProvider"
import { useLabel } from "@/core/context/LabelsContext"
import { isAdminConsolePath, useStartViewAsMember } from "@/core/route/util/routeUtils"
import SignOutIcon from "@/core/ui/iconsax/linear/logout.svg"
import ProfileIcon from "@/core/ui/iconsax/linear/profile-circle.svg"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import AuthenticatedUserDropdownButton from "@components/authenticated-user-dropdown/AuthenticatedUserDropdownButton"
import TestUserDropdown from "@components/test-user/TestUserDropdown"
import { DiscoDropdown, DiscoDropdownProps, DiscoIcon } from "@disco-ui"
import DiscoDropdownItem from "@disco-ui/dropdown/DiscoDropdownItem"
import useSubscriptionStanding from "@utils/hook/useSubscriptionStanding"
import classNames from "classnames"
import React from "react"

interface AuthenticatedUserDropdownProps {
  className?: string
  classes?: { avatarContainer?: string }
  dropdownPosition?: Partial<
    Pick<DiscoDropdownProps, "horizontal" | "vertical" | "transformOrigin">
  >
  logoutOnly?: boolean
}

function AuthenticatedUserDropdown(props: AuthenticatedUserDropdownProps) {
  const { className, classes: propsClasses, dropdownPosition, logoutOnly = false } = props
  const { authUser } = useAuthUser({ required: true })
  const activeOrganization = useActiveOrganization()
  const classes = useStyles()
  const settingsDrawer = useGlobalDrawer("profileSettings")
  const startViewAsMember = useStartViewAsMember()
  const memberLabel = useLabel("admin_member")
  const activeProduct = useActiveProduct()
  const subscriptionStanding = useSubscriptionStanding()
  const hasPlatformAccess = subscriptionStanding === "active"
  const profileDrawer = useGlobalDrawer("profileSettings")

  const logout = useLogout()
  const isOrganizationMember = activeOrganization
    ? Boolean(activeOrganization.viewerMembership)
    : false
  const showViewAs =
    activeProduct?.viewerIsManagerOrInstructor ||
    (activeOrganization?.viewerPermissions.has("members.manage") &&
      hasPlatformAccess &&
      !isAdminConsolePath(location.pathname))

  return (
    <div className={classNames(classes.dropdownContainer, className)}>
      <DiscoDropdown
        menuButton={({ onClick }) => (
          <AuthenticatedUserDropdownButton
            onClick={onClick}
            data-testid={"AuthenticatedUserDropdownButton"}
            classes={{ avatarContainer: propsClasses?.avatarContainer }}
          />
        )}
        menuClasses={{ paper: classes.dropdownStyles }}
        // Transform 500px to the right so it is always next to the scrollbar
        horizontal={dropdownPosition?.horizontal ?? 500}
        vertical={dropdownPosition?.vertical}
        transformOrigin={dropdownPosition?.transformOrigin}
      >
        {!logoutOnly && isOrganizationMember && (
          <>
            {/* My Profile */}
            <DiscoDropdownItem
              testid={"AuthenticatedUserDropdown.profile-settings"}
              onClick={handleOpenProfile}
              icon={<ProfileIcon width={24} height={24} />}
              title={"My Profile"}
            />
            {activeOrganization?.viewerPermissions.has("test_users.manage") && (
              <TestUserDropdown>
                {({ onClick }) => (
                  <DiscoDropdownItem
                    testid={"AuthenticatedUserDropdown.test-users"}
                    onClick={onClick}
                    icon={<DiscoIcon icon={"iconsax.coin"} />}
                    title={"Test Members"}
                    rightIcon={<DiscoIcon icon={"chevron"} rotate={"90"} />}
                  />
                )}
              </TestUserDropdown>
            )}
            {showViewAs && (
              <DiscoDropdownItem
                testid={"AuthenticatedUserDropdown.view-as-member"}
                onClick={startViewAsMember}
                icon={<DiscoIcon icon={"eye"} />}
                title={`Preview as ${memberLabel.singular}`}
              />
            )}
            <DiscoDropdownItem
              testid={"AuthenticatedUserDropdown.notification-preferences"}
              onClick={handleOpenNotificationPreferences}
              icon={<DiscoIcon icon={"bell"} />}
              title={"Notification Preferences"}
            />
          </>
        )}
        {/* Logout */}
        <DiscoDropdownItem
          testid={"AuthenticatedUserDropdown.logout"}
          onClick={logout}
          icon={<SignOutIcon width={24} height={24} />}
          title={"Sign Out"}
        />
      </DiscoDropdown>
    </div>
  )

  function handleOpenProfile() {
    // Open the profile settings drawer
    settingsDrawer.open({
      drawerProfileId: authUser.id,
      profileSettingsTab: "profile",
    })
  }

  function handleOpenNotificationPreferences() {
    profileDrawer.open({
      drawerProfileId: authUser.id,
      profileSettingsTab: "notifications",
    })
  }
}

const useStyles = makeUseStyles((theme) => ({
  dropdownContainer: {
    position: "relative",
  },
  dropdownStyles: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.palette.groovyDepths.insideCard,
    borderRadius: theme.measure.borderRadius.big,
  },
}))

export default React.memo(AuthenticatedUserDropdown)
