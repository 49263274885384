import { StreamChatUserData } from "@/core/context/StreamChatContext"
import Relay from "@/relay/relayUtils"
import UserAvatar from "@/user/common/avatar/UserAvatar"

interface Props {
  user: StreamChatUserData
}

const ChatChannelAvatar = ({ user }: Props) => {
  return (
    <UserAvatar
      className={"str-chat__avatar"}
      placeholderClassName={"str-chat__avatar"}
      user={{
        id: Relay.toGlobalId("User", user.disco_user_id),
        first_name: user.first_name,
        last_name: user.last_name,
        avatar: user.avatar,
        name: user.name,
        is_test_user: user.is_disco_test_user || false,
      }}
      testid={"avatar"}
    />
  )
}

export default ChatChannelAvatar
