import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import React from "react"

// Adds TypeScript support for the <em-emoji> custom element and its props
declare global {
  namespace JSX {
    interface IntrinsicElements {
      "em-emoji": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement>,
        HTMLElement
      > & {
        id?: string
        skin?: string
        size?: string | number
      }
    }
  }
}

export type EmojiObject = {
  colons: string
  emoticons?: string[]
  id: string
  name: string
  native: string
  shortcodes: string
  skin?: string
  unified: string
}

type ChatEmojiProps = {
  emoji: EmojiObject | string
  size?: number
  skin?: string
}

function ChatEmoji({ emoji, size = 24, skin }: ChatEmojiProps) {
  const classes = useStyles()

  const emojiId = typeof emoji === "string" ? emoji : emoji.id
  const emojiSkin = skin || (typeof emoji === "string" ? undefined : emoji.skin)

  return (
    <div className={classes.emoji}>
      <em-emoji id={emojiId} skin={emojiSkin} size={`${size}px`} />
    </div>
  )
}

const useStyles = makeUseStyles(() => ({
  emoji: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
    lineHeight: 0,
    verticalAlign: "middle",
    height: "100%",
  },
}))

export default React.memo(ChatEmoji)
