import { sendSentryAnException } from "@/core/sentryHandler"
import makeUseStyles from "@/core/ui/style/util/makeUseStyles"
import ChatChannelEmojiButton from "@components/chat/channel/emoji-button/ChatChannelEmojiButton"
import ChatChannelEmojiPickerDropdown from "@components/chat/channel/emoji-picker-dropdown/ChatChannelEmojiPickerDropdown"
import { displayErrorToast } from "@components/toast/ToastProvider"
import { DiscoChipSkeleton } from "@disco-ui"
import { useCallback, useRef, useState } from "react"
import { ReactionResponse } from "stream-chat"
import {
  DefaultStreamChatGenerics,
  useChannelStateContext,
  useMessageContext,
} from "stream-chat-react"

const REACTION_FETCH_LIMIT = 300

const ChatChannelReactionList = () => {
  const { message, handleReaction } = useMessageContext<DefaultStreamChatGenerics>()
  const { channel } = useChannelStateContext<DefaultStreamChatGenerics>()
  const classes = useStyles()
  const [isLoading, setIsLoading] = useState(false)
  const reactionsLoadingRef = useRef(false)

  const reactions = message.latest_reactions || []
  const ownReactionsTypes = (message.own_reactions || []).map((reaction) => reaction.type)

  const reactionCounts = reactions.reduce((counts: Record<string, number>, reaction) => {
    const { type } = reaction
    if (type) {
      counts[type] = (counts[type] || 0) + 1
    }
    return counts
  }, {})

  const handleEmojiClick = useCallback(
    (emoji: string) => {
      handleReaction(emoji, undefined as any)
    },
    [handleReaction]
  )

  if (isLoading) {
    return (
      <ChatChannelReactionListSkeleton count={Object.keys(reactionCounts).length || 3} />
    )
  }

  if (Object.keys(reactionCounts).length === 0) return null

  return (
    <ol className={classes.list}>
      {Object.keys(reactionCounts)
        .sort()
        .map((emoji) => (
          <li key={emoji}>
            <ChatChannelEmojiButton
              variant={"reaction-list"}
              emoji={emoji}
              reacted={ownReactionsTypes.includes(emoji)}
              reactionCount={reactionCounts[emoji]}
              reactions={reactions.filter((r) => r.type === emoji)}
              loadReactions={loadReactions}
            />
          </li>
        ))}

      <li>
        <ChatChannelEmojiPickerDropdown
          onEmojiSelect={handleEmojiClick}
          classes={classes.iconButton}
        />
      </li>
    </ol>
  )

  async function loadReactions() {
    if (reactionsLoadingRef.current) return
    reactionsLoadingRef.current = true
    setIsLoading(true)
    try {
      const totalCount = Object.values(reactionCounts).reduce(
        (sum, count) => sum + count,
        0
      )
      let newReactions: ReactionResponse[] = []
      let offset = 0
      do {
        const res = await channel.getReactions(message.id || "", {
          limit: REACTION_FETCH_LIMIT,
          offset,
        })
        newReactions = newReactions.concat(res.reactions)
        offset += REACTION_FETCH_LIMIT
      } while (offset < totalCount)
      channel.state._updateMessage(
        {
          id: message.id,
        },
        (msg) => ({
          ...msg,
          latest_reactions: newReactions,
        })
      )
    } catch (error) {
      sendSentryAnException(error)
      displayErrorToast("Unable to get reactions, please try again later")
    } finally {
      reactionsLoadingRef.current = false
      setIsLoading(false)
    }
  }
}

const useStyles = makeUseStyles((theme) => ({
  iconButton: {
    "& svg": {
      color:
        theme.palette.type === "dark"
          ? theme.palette.groovy.grey[100]
          : theme.palette.groovy.grey[400],
    },
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  list: {
    paddingLeft: "0 !important",
    gap: theme.spacing(0.5),
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",

    "& li": {
      listStyle: "none",
    },
  },
}))

interface ReactionListSkeletonProps {
  count?: number
}
export function ChatChannelReactionListSkeleton({
  count = 3,
}: ReactionListSkeletonProps) {
  const classes = useStyles()
  return (
    <ol className={classes.list}>
      {[...new Array(count)].map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <li key={index}>
          <DiscoChipSkeleton width={42} />
        </li>
      ))}
    </ol>
  )
}

export default ChatChannelReactionList
