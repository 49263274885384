import Relay from "@/relay/relayUtils"
import { ProfileAvatarSize } from "@/user/common/avatar/ProfileAvatar"
import makeUseStyles from "@assets/style/util/makeUseStyles"
import { getAvatarBorderWidth } from "@components/avatar/Avatar"
import { DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import classNames from "classnames"
import { CSSProperties, ReactNode } from "react"
import seedrandom from "seedrandom"

interface AvatarPlaceholderProps {
  testid?: string
  text: ReactNode
  textClassName?: string
  className?: string
  /** Override the placeholder text color */
  color?: string
  /** Override the placeholder background color */
  backgroundColor?: string
  /** The UUID to generate the default background color. It can be a rawId or globalId */
  uuid?: string
  style?: CSSProperties
  size?: ProfileAvatarSize
  dropShadow?: boolean
  isTestUser: boolean
}

function AvatarPlaceholder({
  testid,
  text,
  textClassName,
  className: customClassName,
  backgroundColor: customBackgroundColor,
  color: customColor,
  uuid,
  isTestUser,
  dropShadow = false,
  size = 24,
}: AvatarPlaceholderProps) {
  // Memoize the index so that we don't have to recalculate it on every render
  const styles = useAvatarDefaultStyles(size, uuid)
  const theme = useTheme()
  const classes = useStyles({
    ...styles,
    size,
    backgroundColor: customBackgroundColor || styles.backgroundColor,
    color: customColor || theme.palette.common.white,
    isTestUser,
  })

  const textComponent = (
    <DiscoText data-testid={`${testid}.text`} className={textClassName}>
      {text}
    </DiscoText>
  )

  return (
    <div
      className={classNames(classes.avatarPlaceholder, customClassName, {
        [classes.dropShadow]: dropShadow,
      })}
    >
      {isTestUser ? (
        <div className={classNames(classes.innerAvatarPlaceholder)}>{textComponent}</div>
      ) : (
        textComponent
      )}
    </div>
  )
}

/**
 * Use the UUID to determine the color index
 * It is designed to work with both raw id and global id
 *
 * */
export function getAvatarColorIndexByUUID(uuid?: string) {
  let rawId = "default"
  if (uuid) {
    // Try to extract the raw id from the UUID
    try {
      rawId = Relay.rawId(uuid)
    } catch {
      // UUID is not a global ID, use it as-is
      rawId = uuid
    }
  }

  const rng = seedrandom(rawId)
  return Math.floor(rng() * 10)
}

export function useGetAvatarColorByUUID(uuid?: string) {
  const theme = useTheme()
  const colors = [
    theme.palette.groovy.red[400],
    theme.palette.groovy.green[500],
    theme.palette.groovy.blue[400],
    theme.palette.groovy.purple[400],
    theme.palette.groovy.grey[700],
    "#419095",
    theme.palette.groovy.orange[400],
    theme.palette.groovy.grey[600],
    theme.palette.groovy.yellow[400],
    "#0D60AC",
  ]

  return colors[getAvatarColorIndexByUUID(uuid)]
}

export function useAvatarDefaultStyles(
  size: ProfileAvatarSize,
  uuid?: string
): Pick<StyleProps, "backgroundColor" | "fontSize"> {
  const AVATAR_STYLES: Record<ProfileAvatarSize, Pick<StyleProps, "fontSize">> = {
    20: { fontSize: "8px" },
    24: { fontSize: "10px" },
    32: { fontSize: "14px" },
    40: { fontSize: "18px" },
    48: { fontSize: "22px" },
    64: { fontSize: "28px" },
    96: { fontSize: "44px" },
    120: { fontSize: "56px" },
  }

  const backgroundColor = useGetAvatarColorByUUID(uuid)

  return {
    backgroundColor,
    ...AVATAR_STYLES[size],
  }
}

type StyleProps = {
  backgroundColor: string
  size: ProfileAvatarSize
  fontSize: string
  color?: string
  isTestUser: boolean
}

const useStyles = makeUseStyles((theme) => ({
  avatarPlaceholder: ({
    backgroundColor,
    size,
    color,
    fontSize,
    isTestUser,
  }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor,
    borderRadius: "50%",
    aspectRatio: "1/1",
    width: size,
    height: size,
    border: `${getAvatarBorderWidth(size)}px solid ${
      isTestUser
        ? theme.palette.type === "dark"
          ? theme.palette.common.white
          : theme.palette.common.black
        : theme.palette.common.white
    }`,
    // Use a higher specificity to set the placeholder text styles with to override other styles
    "& span, & p": {
      ...theme.typography["body-xs"],
      ...theme.typography.modifiers.fontWeight[500],
      ...theme.typography.modifiers.textTransform.uppercase,
      fontSize,
      color: `${color} !important`,
    },
  }),
  // Only shows if it's NOT a test user
  innerAvatarPlaceholder: ({ size }: StyleProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "50%",
    width: "100%",
    height: "100%",
    border: `${getAvatarBorderWidth(size)}px solid ${
      theme.palette.type === "dark"
        ? theme.palette.common.black
        : theme.palette.common.white
    }`, // Use double borders because outline makes the avatar look bigger since it ignores box sizing
  }),
  dropShadow: {
    boxShadow: theme.palette.groovyDepths.insideCard,
  },
}))

export default AvatarPlaceholder
