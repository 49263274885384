import makeUseStyles from "@assets/style/util/makeUseStyles"
import { DiscoDivider, DiscoText } from "@disco-ui"
import { useTheme } from "@material-ui/core"
import isToday from "date-fns/isToday"
import { DateSeparatorProps } from "stream-chat-react"
import useUserTimezone from "../../../../../user/util/useUserTimezone"
import { DATE_FORMAT } from "../../../../../utils/time/timeConstants"
import { formatDateWithOptions } from "../../../../../utils/time/timeUtils"

type ChatChannelDetailDateSeparatorProps = DateSeparatorProps

function ChatChannelDetailDateSeparator({
  date,
  unread,
}: ChatChannelDetailDateSeparatorProps) {
  const timeZone = useUserTimezone()
  const classes = useStyles()
  const theme = useTheme()

  return (
    <div className={classes.container}>
      <DiscoDivider
        color={unread ? theme.palette.groovy.red[300] : undefined}
        thickness={2}
        marginTop={2.5}
        marginBottom={2.5}
      />
      <DiscoText noWrap variant={"body-xs-600"} className={classes.date}>
        {getFormattedDate()}
      </DiscoText>
    </div>
  )

  function getFormattedDate() {
    let formattedDate = "Today"
    if (unread) {
      formattedDate = "New"
    } else if (!isToday(date)) {
      formattedDate = formatDateWithOptions({
        timeZone,
        format: DATE_FORMAT.DATE_WITH_WEEK_DAY,
      })(date)
    }
    return formattedDate
  }
}

const useStyles = makeUseStyles((theme) => ({
  container: {
    position: "relative",

    "&:hover": {
      backgroundColor: theme.palette.background.paper,
    },
  },
  date: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(0.5, 2, 0.75),
    border: theme.palette.constants.borderSmall,
    borderRadius: theme.spacing(3),
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translateY(-50%) translateX(-50%)",
  },
}))

export default ChatChannelDetailDateSeparator
